import React, { createContext, useState } from 'react';

const ContextTimeScheduling = createContext({
  openDialog: false,
  setOpenDialog: () => {},
  contextFormData: {},
  setcontextFormData: () => {}, // Default empty function
});

const TimeSchedulingProvider = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contextFormData, setcontextFormData] = useState({});

  return (
    <ContextTimeScheduling.Provider value={{ openDialog, setOpenDialog, contextFormData, setcontextFormData }}>
      {children}
    </ContextTimeScheduling.Provider>
  );
};

export { ContextTimeScheduling, TimeSchedulingProvider };
