import loadConfig from "../../../../config";

export const DataService = async () => {
  const config = await loadConfig();

  const getDataSmall = (data) => Promise.resolve(data.slice(0, 10));
  const getDataMedium = (data) => Promise.resolve(data.slice(0, 50));
  const getDataLarge = (data) => Promise.resolve(data.slice(0, 200));
  const getDataXLarge = (data) => Promise.resolve(data);

  const getData = async (params) => {
    const queryParams = params
      ? Object.keys(params)
          .map(
            (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
          )
          .join("&")
      : "";
    const response = await fetch(
      `${config.pythonBaseURL}/sf/sortAndFilter/?${queryParams}`
    );
    return response.json();
  };

  const sendEditedDataToBackend = async (data) => {
    // console.log(data);
    const response = await fetch(`${config.pythonBaseURL}/api/edited-data/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const sendRowIndexToBackend = async (data) => {
    // console.log(data);
    const response = await fetch(`${config.pythonBaseURL}/api/delete/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const sendRowDataToBackend = async (data) => {
    try {
      const response = await fetch(`${config.pythonBaseURL}/api/row/`, {
        // Ensure the correct endpoint
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // Check if the response is ok (status in the range 200-299)
      if (!response.ok) {
        const errorText = await response.text(); // Get the error text for debugging
        throw new Error(`Error ${response.status}: ${errorText}`);
      }

      return await response.json(); // Parse JSON response if successful
    } catch (error) {
      console.error("Error sending row data to backend:", error); // Log any errors
      throw error; // Rethrow error for further handling if necessary
    }
  };

  const sendReplaceToBackend = async (data) => {
    const response = await fetch(`${config.pythonBaseURL}/api/replace/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  return {
    getDataSmall,
    getDataMedium,
    getDataLarge,
    getDataXLarge,
    getData,
    sendEditedDataToBackend,
    sendRowIndexToBackend,
    sendRowDataToBackend,
    sendReplaceToBackend,
  };
};
