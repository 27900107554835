import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import { createAxiosInstance as myAxios } from "../services/Helper";
import { Toast } from "primereact/toast";

const UserRegistration = ({ onClose, mail }) => {
  const toast = useRef(null);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [isEditPage, setIsEditPage] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    phone: "",
    designation: "",
    isadmin: "",
    permissions: [],
    userpassword: "",
    repeatPassword: "",
  });

  useEffect(() => {
    if (mail) {
      setIsEditPage(true);
      const fetchData = async () => {
        try {          
          const axiosInstance = await myAxios()
          const response = await axiosInstance.get(`users/edit/getuser/${mail}`); // Adjust the API endpoint as needed
          const data = response.data;
          setFormData({
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            gender: data.gender || "",
            email: data.email || "",
            phone: data.phone || "",
            designation: data.designation || "",
            isadmin: data.isadmin || "",
            permissions: data.permissions ? data.permissions.split(",") : [], // Convert to array
            userpassword: "",
            repeatPassword: "",
          });
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      };
      fetchData();
    } else {
      setIsEditPage(false);
      setFormData({
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        phone: "",
        designation: "",
        isadmin: "",
        permissions: [],
        userpassword: "",
        repeatPassword: "",
      });
    }
  }, [mail]);

  useEffect(()=>{
   setIsValidEmail(emailRegex.test(formData.email));

  }, [formData.email])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const handlePasswordCheckboxChange = (e) => {
    setShowPasswordFields(e.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if(!isValidEmail){
      toast.current.show({
        severity: "error",
        summary: "Invalid Email",
        detail: "Please provide a valid email id",
        life: 3000,
      });
      return;
    }
    if(isEditPage){
      let requiredFields = [
        "firstName",
        "lastName",
        "gender",
        "email",
        "phone",
        "designation",
        "permissions",
      ];


      let isValid = requiredFields.every((field) => {
        let value = formData[field];
  
        if (typeof value === "string") {
          return value.trim() !== "";
        }
  
        // Handle non-string values
        // For example, consider arrays as invalid if they are empty
        if (Array.isArray(value)) {
          return value.length > 0;
        }
  
        // Handle other types as needed
        // Here, you could return false or handle objects, numbers, etc.
        return false;
      });

      if (!isValid) {
        // !true
        // Show error toast
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill in all required fields",
          life: 3000,
        });
        return;
      }


    }else {

      
      let requiredFields = [
        "firstName",
        "lastName",
        "gender",
        "email",
        "phone",
        "designation",
        "permissions",
        "userpassword",
        "repeatPassword",
      ];
      
      if (formData.userpassword !== formData.repeatPassword) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Password does not matched `,
          life: 3000,
        });
        return;
      }

      let isValid = requiredFields.every((field) => {
        let value = formData[field];
  
        if (typeof value === "string") {
          return value.trim() !== "";
        }
  
        // Handle non-string values
        // For example, consider arrays as invalid if they are empty
        if (Array.isArray(value)) {
          return value.length > 0;
        }
  
        // Handle other types as needed
        // Here, you could return false or handle objects, numbers, etc.
        return false;
      });

      if (!isValid) {
        // !true
        // Show error toast
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill in all required fields",
          life: 3000,
        });
        return;
      }


    }


    try {
      if (!isEditPage) {
        const axiosInstance = await myAxios()
        let response = await axiosInstance.post("users/register", formData);
        let msg = response.data;
        if (msg === "Email is Existed ") {
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: `Email Already Exists`,
            life: 3000,
          });
          return;
        }
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "User Updated Successfully",
          life: 3000,
        });
        setTimeout(() => {
          onClose();
        }, 500);
      } else if (isEditPage) {
        try {
          const axiosInstance = await myAxios()
          let response = await axiosInstance.post(
            `users/updateUser/${mail}`,
            formData
          );
          // console.log(response);
          let msg = response.data;
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "User Updated Successfully",
            life: 3000,
          });
          setTimeout(() => {
            onClose();
          }, 500);
        } catch (e) {
          console.log(e.data);
        }
      }
    } catch (e) {
      console.log(e.data);
    }
  };

  // This is for the Permissions Drop Down
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState(null);

  const permissionsList = ["ADMIN", "USER", "DEV", "QA"];

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleCheckboxChange = (permission) => {
    const selectedIndex = selectedPermissions.indexOf(permission);
    let newSelected = [...selectedPermissions];

    if (selectedIndex === -1) {
      newSelected = [...newSelected, permission];
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedPermissions(newSelected);

    // Update the permissions field in formData
    setFormData({
      ...formData,
      permissions: newSelected, // Update permissions with newSelected array
    });
  };

  const genderOptions = ["Male", "Female"];
  const approveOptions = ["Yes", "No"];
  const isAdminOptions = ["Yes", "No"];

  return (
    <>
      <Toast ref={toast} />
      {/* {console.log(formData)} */}
      <Sidebar
        visible={true}
        position="right"
        onHide={onClose}
        className="w-full md:w-4 lg:w-3"
      >
        <h2>{isEditPage ? "EditUser" : "Register"}</h2>
        <div className="w-full mt-5">
          <form onSubmit={handleSubmit} className="flex flex-column gap-5">
            <FloatLabel>
              <InputText
                id="firstName"
                name="firstName"
                className="w-full p-inputtext-sm"
                value={formData.firstName}
                onChange={handleChange}
              />
              <label htmlFor="firstName">First Name</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="lastName"
                name="lastName"
                className="w-full p-inputtext-sm"
                value={formData.lastName}
                onChange={handleChange}
              />
              <label htmlFor="lastName">Last Name</label>
            </FloatLabel>

            <div className="flex flex-column mt-m1">
              <label htmlFor="gender" className="text-xs">
                Gender
              </label>
              <SelectButton
                id="gender"
                name="gender"
                className="p-selectbutton-xs"
                value={formData.gender}
                onChange={handleChange}
                options={genderOptions}
              />
            </div>

            <FloatLabel>
              <InputText
                id="email"
                name="email"
                type="email"
                className="w-full p-inputtext-sm"
                value={formData.email}
                onChange={handleChange}
                disabled = {isEditPage ? true : false}
              />
              <label htmlFor="email">Email</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="phone"
                name="phone"
                type="tel"
                className="w-full p-inputtext-sm"
                value={formData.phone}
                onChange={handleChange}
              />
              <label htmlFor="phone">Phone Number</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="designation"
                name="designation"
                className="w-full p-inputtext-sm"
                value={formData.designation}
                onChange={handleChange}
              />
              <label htmlFor="designation">Designation</label>
            </FloatLabel>

            <FloatLabel>
              <MultiSelect
                value={formData.permissions}
                onChange={handleChange}
                options={permissionsList}
                placeholder="Select Permissions"
                maxSelectedLabels={3}
                className="w-full"
                name="permissions"
              />
              <label htmlFor="permissions">Permissions</label>
            </FloatLabel>

            {/* {isEditPage && (
              <div className="flex align-items-center">
                <Checkbox
                  inputId="changePassword"
                  checked={showPasswordFields}
                  onChange={handlePasswordCheckboxChange}
                />
                <label htmlFor="changePassword" className="ml-2">
                  Change Password
                </label>
              </div>
            )} */}

            { !isEditPage && (
            // {(!isEditPage || showPasswordFields) && (
              <>
                <FloatLabel>
                  <Password
                    id="userpassword"
                    name="userpassword"
                    className="w-full"
                    value={formData.userpassword}
                    feedback={false}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <label htmlFor="userpassword">Password</label>
                </FloatLabel>

                <FloatLabel>
                  <Password
                    id="repeatPassword"
                    name="repeatPassword"
                    className="w-full"
                    value={formData.repeatPassword}
                    feedback={false}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <label htmlFor="repeatPassword">Confirm Password</label>
                </FloatLabel>
              </>
            )}

            <Button
              label={isEditPage ? "Update" : "Register"}
              type="submit"
              icon="pi pi-check"
              severity="primary"
              className="border-round-lg"
            />
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default UserRegistration;
