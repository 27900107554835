import React, { useState, useContext, useRef, useEffect } from "react";
import { ContextTimeScheduling } from "../../components/scheduleComponent/ContextTimeScheduling";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import Breadcrumbs from "../../components/Breadcrumb";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";

const parseCronExpression = (cronExpression) => {
  const parts = cronExpression.split(" ");

  const getValueAfterSlash = (part) => {
    if (part.includes("/")) {
      const splitPart = part.split("/");
      return splitPart[1]; // Return the value after '/'
    } else if (part === "*")
      return "0"; // Return the original part if '/' is not present
    else return part;
  };
  return {
    seconds: getValueAfterSlash(parts[0]), // Assuming seconds is included in the cron expression
    minutes: getValueAfterSlash(parts[1]),
    hours: getValueAfterSlash(parts[2]),
    dayOfMonth: getValueAfterSlash(parts[3]),
    month: getValueAfterSlash(parts[4]),
    dayOfWeek: getValueAfterSlash(parts[5]),
  };
};

const EditDatabaseToDb = () => {
  const [ruleData, setRuleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  //added for limit query.
  const [limitErrorMessage, setLimitErrorMessage] = useState("");
  const [isLimitValid, setIsLimitValid] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { ruleId } = location.state || {};
  // const navigate = useNavigate();
  const toast = useRef(null);
  const { setOpenDialog, setcontextFormData } = useContext(
    ContextTimeScheduling
  );

  const [formData, setFormData] = useState({
    jobname: "",

    sourceDbUrl: "",
    sourceTable: "",
    sourceDbUser: "",
    sourceDbPassword: "",
    queryText: "",
    addQuery: false,
    targetTable: "",
    targetDbUrl: "",
    targetDbUser: "",
    targetDbPassword: "",
    //////////////////////////
    isInsertQuery: false,
    insertQueryText: "",
  });

  useEffect(() => {
    if (ruleId === undefined) navigate("/home");
    const fetchData = async () => {
      try {
        const axiosInstance = await myAxios();
        const response = await axiosInstance.get(`/dtd/sch/getByID/${ruleId}`);
        setRuleData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }
  }, [ruleId]);

  useEffect(() => {
    if (ruleData) {
      const cronValues = parseCronExpression(ruleData.cronExpression);
      const startTime = ruleData.startDate
        ? ruleData.startDate.split("T")[1]
        : "";
      setFormData({
        jobname: ruleData.jobname || "",
        sourceDbUrl: ruleData.sourceDbUrl || "",
        sourceTable: ruleData.sourceTable || "",
        sourceDbUser: ruleData.sourceDbUser || "",
        sourceDbPassword: ruleData.sourceDbPassword || "",
        archiveLocation: ruleData.archiveLocation || "",
        queryText: ruleData.queryText || "",
        insertQueryText: ruleData.insertQueryText || "",
        targetTable: ruleData.targetTable || "",
        targetDbUrl: ruleData.targetDbUrl || "",
        targetDbUser: ruleData.targetDbUser || "",
        targetDbPassword: ruleData.targetDbPassword || "",
        ...cronValues,
        timeZone: ruleData.timeZone || "",
        startDate: ruleData.startDate || "",
        startTime: startTime || "",
        endDate: ruleData.endDate || "",
        ruleId: ruleData.id || "",
        addQuery: !!ruleData.queryText,
        isInsertQuery: !!ruleData.insertQueryText,
      });
    }
  }, [ruleData]);

  /////////////////////////////////////////////////////////////////////
  // added for the database connection
  const [databaseConnectionTest, setDatabaseConnectionTest] = useState({
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    tableName: "",
  });

  useEffect(() => {
    if (databaseConnectionTest) {
      if (checkDatabaseConnectionValid()) {
        setLoading(true);
        setVisible(true);
        callTestDatabase();
      }
    }
  }, [databaseConnectionTest]);

  const checkDatabaseConnectionValid = () => {
    for (const key in databaseConnectionTest) {
      if (databaseConnectionTest[key] === "") {
        return false;
      }
    }

    return true;
  };

  // handle the database  ApI call here
  const callTestDatabase = async () => {
    try {
      const axiosInstance = await myAxios();
      let sftp_connect = await axiosInstance
        .post("/test/con/db", databaseConnectionTest)
        .then((res) => res.data)
        .catch((e) => e.response.data);
      // console.log(sftp_connect);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      // update code added here for message and status
      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const handleTestForDBConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (
        formData.sourceDbPassword.trim() === "" ||
        formData.sourceDbUser.trim() === "" ||
        formData.sourceDbUrl.trim() === "" ||
        formData.sourceTable.trim() === ""
      ) {
        // console.log(checkDatabaseConnectionValid() , " sftp details ") ;
        // console.log(databaseConnectionTest);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Source's Database required fields  .",
          life: 3000,
        });
        return;
      } else {
        setDatabaseConnectionTest({
          dbUrl: formData.sourceDbUrl,
          dbUser: formData.sourceDbUser,
          dbPass: formData.sourceDbPassword,
          tableName: formData.sourceTable,
        });
      }
    }

    if (buttonType === 2) {
      if (
        formData.targetDbPassword.trim() === "" ||
        formData.targetDbUser.trim() === "" ||
        formData.targetDbUrl.trim() === "" ||
        formData.targetTable.trim() === ""
      ) {
        // console.log(checkDatabaseConnectionValid() , " sftp details ") ;
        // console.log(databaseConnectionTest);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Target's Database required fields .",
          life: 3000,
        });
        return;
      } else {
        setDatabaseConnectionTest({
          dbUrl: formData.targetDbUrl,
          dbUser: formData.targetDbUser,
          dbPass: formData.targetDbPassword,
          tableName: formData.targetTable,
        });
      }
    }
  };

  //////////////////////////////////////////////////////////////////////

  const handleClick = (e) => {
    e.preventDefault();

    // Validation: Check if all required fields are filled
    const requiredFields = [
      "jobname",
      "sourceDbUrl",
      "sourceTable",
      "sourceDbUser",
      "sourceDbPassword",
      "targetTable",
      "targetDbUrl",
      "targetDbUser",
      "targetDbPassword",
    ];

    const isValid = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );

    // adding for limit query error
    if (!isLimitValid) {
      toast.current.show({
        severity: "error",
        summary: "SQL Query Limit ",
        detail: "You can take Limited Query Amount else Opt for offset",
        life: 2000,
      });
      return;
    }

    if (!isValid) {
      // Show error toast
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields",
        life: 3000,
      });
      return;
    }

    setcontextFormData(formData);
    setOpenDialog(true); // This should trigger the dialog to open
  };

  ////////////////////////////////////////////////////

  // useEffect to see the values
  useEffect(() => {
    // Regular expression to find the number after the LIMIT keyword
    const limitMatch = formData.queryText.match(/LIMIT\s+(\d+)/i);

    if (limitMatch) {
      const limitValue = limitMatch[1]; // Extracted number
      if (limitValue > 100000) {
        setLimitErrorMessage("Cant' Exceed more than 1 lakh");
        setIsLimitValid(false);
        // console.log(
        //   "can't go more than 1 lakh records try to break down in smaller chunk with the limit and offset value "
        // );
      } else {
        // console.log("Limit value:", limitValue); // Output: 10
        setLimitErrorMessage("");
        setIsLimitValid(true);
      }
    } else {
      // console.log("No LIMIT clause found");
      setLimitErrorMessage("");
      setIsLimitValid(true);
    }
  }, [formData.queryText, limitErrorMessage]);

  /////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    if (name === "addQuery" && !val) {
      // Reset queryText if addQuery is unchecked
      setFormData({ ...formData, [name]: val, queryText: "" });
    } else if (name === "isInsertQuery" && !val) {
      setFormData({ ...formData, [name]: val, insertQueryText: "" });
    } else {
      setFormData({ ...formData, [name]: val });
    }
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  return (
    <>
      <div style={{ marginLeft: "25px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <span className="text-2xl font-bold">
                Database To Database Transfer
              </span>
            </div>

            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  required
                />
              </div>
            </div>

            <div className="col-12 sm:col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Database URL
                    </label>
                    <InputText
                      type="password"
                      id="dns2_input"
                      name="sourceDbUrl"
                      className="w-full"
                      value={formData.sourceDbUrl}
                      onChange={handleChange}
                      placeholder="Enter Database URL"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="tableName_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="tableName_input"
                      name="sourceTable"
                      className="w-full"
                      value={formData.sourceTable}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="username2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      User Name
                    </label>
                    <InputText
                      id="username2_input"
                      name="sourceDbUser"
                      className="w-full"
                      value={formData.sourceDbUser}
                      onChange={handleChange}
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="password2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Password
                    </label>
                    <Password
                      id="password2_input"
                      name="sourceDbPassword"
                      className="w-full"
                      value={formData.sourceDbPassword}
                      feedback={false}
                      onChange={handleChange}
                      placeholder="********"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="addQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="addQuery"
                      name="addQuery"
                      checked={formData.addQuery}
                      onChange={handleChange}
                    />
                  </div>

                  {formData.addQuery && (
                    <div className="group">
                      {limitErrorMessage && (
                        <p style={{ color: "red" }}>{limitErrorMessage}</p>
                      )}
                      <InputTextarea
                        name="queryText"
                        placeholder="Enter SQL query here"
                        className="w-full"
                        autoResize
                        value={formData.queryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>

                {/* <div className="col-12"></div> */}
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dbUrl_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Database URL
                    </label>
                    <InputText
                      type="password"
                      id="targetDbUrl"
                      name="targetDbUrl"
                      className="w-full"
                      value={formData.targetDbUrl}
                      onChange={handleChange}
                      placeholder="Enter Database URL"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="targetTable"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="targetTable"
                      name="targetTable"
                      className="w-full"
                      value={formData.targetTable}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dbUser_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      User Name
                    </label>
                    <InputText
                      id="targetDbUser"
                      name="targetDbUser"
                      className="w-full"
                      value={formData.targetDbUser}
                      onChange={handleChange}
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dbPass_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Password
                    </label>
                    <Password
                      id="targetDbPassword"
                      name="targetDbPassword"
                      className="w-full"
                      value={formData.targetDbPassword}
                      feedback={false}
                      onChange={handleChange}
                      placeholder="********"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="isInsertQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="isInsertQuery"
                      name="isInsertQuery"
                      checked={formData.isInsertQuery}
                      onChange={handleChange}
                    />
                  </div>

                  {formData.isInsertQuery && (
                    <div className="group">
                      <InputTextarea
                        name="insertQueryText"
                        placeholder="Enter SQL query here(insert query)"
                        className="w-full"
                        autoResize
                        value={formData.insertQueryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 2)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <Dialog
                  header="Connection Test"
                  visible={visible}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible(false)}
                  footer={footerContent}
                >
                  <Card>
                    {" "}
                    {loading ? (
                      <div className="card flex justify-content-center">
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="5"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <>
                        <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                        <p className="m-0">Message: {responseMessage}</p>
                      </>
                    )}
                  </Card>
                </Dialog>

                <div className="col-12"></div>
              </div>
            </div>

            <div className="col-4"></div>
            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>
        </form>
        <Toast ref={toast} />
      </div>
    </>
  );
};
export default EditDatabaseToDb;
