import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomeNavbar.css"; // Ensure your styles are applied here
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import logoImg from "./Assets/logoLarge.png";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const handleNavigatin = () => {
    navigate("/login");
  };

  const handleCompany = () => {
    navigate("/companyRegistration");
  };

  const startContent = <Link to="/login" className="logo-header"></Link>;

  const endContent = (
    <ul className="menu list-none m-0 p-0 flex flex-row align-items-center justify-content-center gap-3 mx-5 text-base">
      <h4 onClick={handleCompany} style={{ cursor: "pointer" }}>
        Company Registration
      </h4>
      <Button
        label="Login"
        type="submit"
        severity="primary"
        onClick={handleNavigatin}
        className="border-round-lg"
      />
    </ul>
  );

  return (
    <>
      <Toast ref={toast} />
      <Toolbar
        start={startContent}
        end={endContent}
        className="border-noround"
      />
      <div
        style={{
          backgroundColor: "#070707",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="background-container">
          <div
            className="card"
            style={{
              background: "#070707",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Image
              src={logoImg}
              alt="Logo"
              width="700"
              style={{ display: "block", margin: "0 auto" }}
            />
            <div className="text-section">
              <p
                className="overview-text large-text"
                style={{ color: "white" }}
              >
                DocPI is designed with a deep understanding of the intricacies
                involved in data management. DocPI provides a visual interface
                that abstracts the underlying code, allowing technical users to
                focus on strategy and optimization rather than routine coding
                tasks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
