import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { ContextTimeScheduling } from "./ContextTimeScheduling";
import { useLocation } from "react-router-dom";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import { Messages } from "primereact/messages";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

const TimeScheduling = () => {
  const toastTopRight = useRef(null);
  const toastTopCenter = useRef(null);
  const toastmsg = useRef(null);
  const navigate = useNavigate();
  const { openDialog, setOpenDialog, contextFormData } = useContext(
    ContextTimeScheduling
  );
  const [selectedTime, setSelectedTime] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeZone, settimeZone] = useState(null);

  //this is for the edit timeScheduling Page
  const location = useLocation();

  const [values, setValues] = useState({
    hours: "0",
    minutes: "0",
    days: "0",
    weeks: "0",
    months: "0",
  });
  //this schedule is for update Rules
  const isEditMode = location.pathname.includes("edit");
  useEffect(() => {
    if (isEditMode) {
      setValues({
        hours: contextFormData.hours,
        minutes: contextFormData.minutes,
        days: contextFormData.dayOfMonth,
        weeks: contextFormData.dayOfWeek,
        months: contextFormData.month,
      });
      setStartDate(new Date(contextFormData.startDate));
      setEndDate(new Date(contextFormData.endDate));
      settimeZone(contextFormData.timeZone);
      let inputDate = new Date(contextFormData.startDate);
      setSelectedTime(inputDate);
    } else {
      setValues({
        hours: "0",
        minutes: "0",
        days: "0",
        weeks: "0",
        months: "0",
      });
      setStartDate("");
      setEndDate("");
      settimeZone("");
      let inputDate = "";
      setSelectedTime("");
    }
  }, [contextFormData, location.pathname]);
  const [selectedOption, setSelectedOption] = useState("once");

  const today = new Date();
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSelect1 = (option) => {
    settimeZone(option.label);
  };

  const options = [
    { id: "once", label: "Only Once" },
    { id: "hoursMinutes", label: "Hours and Minutes" },
    { id: "days", label: "Days" },
    { id: "weeks", label: "Weeks" },
    { id: "months", label: "Months" },
  ];

  const options1 = [
    { id: "CST", label: "America/Chicago" },
    { id: "EST", label: "Asia/Kolkata" },
    { id: "ACT", label: "America/Cuiaba" },
  ];

  const handleChange = (e, property) => {
    setValues({
      ...values,
      [property]: e.value,
    });
  };

  const formatDate = (date) => {
    if (!date) return ""; // Handle null case gracefully
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (time) => {
    return time
      ? `${time.getHours().toString().padStart(2, "0")}:${time
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
      : ""; // Format time to "HH:mm"
  };

  // added here for reset when you choose one of the once or day , month like frequency
  useEffect(() => {
    setValues({
      hours: "0",
      minutes: "0",
      days: "0",
      weeks: "0",
      months: "0",
    });
  }, [selectedOption]);

  const combinedData = {
    ...contextFormData,
    ...values,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    timeZone,
    selectedTime: formatTime(selectedTime),
    email: localStorage.getItem("userId"),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;
      //if this is the update rule page
      if (isEditMode) {
        try {
          //if the user didn't change the date and the date is before today and try to submint below will prevent from that
          // Get the current date
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to ignore time part in comparison

          // Parse startDate and endDate from combinedData
          const startDate = new Date(combinedData.startDate);
          const endDate = new Date(combinedData.endDate);

          // Validate dates
          if (startDate < today || endDate < today) {
            toastTopCenter.current.show({
              severity: "error",
              summary: "Error",
              detail:
                "While Updateing Job Start Date and End Date must not be before today date",
              life: 3000,
            });
            return;
          }

          const idCode = contextFormData.ruleId.substring(0, 3);
          if (idCode === "mtm") {
            const axiosInstance = await myAxios();
            response = await axiosInstance.put(
              `hmtm/update/${contextFormData.ruleId}`,
              combinedData
            );
          } else if (idCode === "mtd") {
            const axiosInstance = await myAxios();
            response = await axiosInstance.put(
              `mtdb/update/${contextFormData.ruleId}`,
              combinedData
            );
          } else if (idCode === "dtm") {
            const axiosInstance = await myAxios();
            response = await axiosInstance.put(
              `dbtm/update/${contextFormData.ruleId}`,
              combinedData
            );
          } else if (idCode === "dtd") {
            const axiosInstance = await myAxios();
            response = await axiosInstance.put(
              `dbtodb/update/${contextFormData.ruleId}`,
              combinedData
            );
          }

          // Need to add for DB to DB here //TODO

          toastTopRight.current.show({
            severity: "info",
            summary: "Info",
            detail: "Job Updated Successfully.",
            life: 3000,
          });

          navigate("/home");
        } catch (e) {
          if (e.response && e.response.status === 401) {
            navigate("/login");
          } else {
            console.error(e);
          }
        }
      }
      //if this is normal schedule page
      else {
        if (location.pathname === "/sftp-database") {
          const axiosInstance = await myAxios();
          response = await axiosInstance.post("mtdb/start", combinedData);
          toastTopRight.current.show({
            severity: "success",
            summary: "Success",
            detail: "Mount To Database Transfer Success",
            life: 3000,
          });
        } else if (location.pathname === "/sftp-transfer") {
          const axiosInstance = await myAxios();
          response = await axiosInstance.post("hmtm/start", combinedData);
          toastTopRight.current.show({
            severity: "success",
            summary: "Success",
            detail: "Mount To Mount Transfer Success",
            life: 3000,
          });
        } else if (location.pathname === "/database-sftp") {
          const axiosInstance = await myAxios();
          response = await axiosInstance.post("dbtm/start", combinedData);
          toastTopRight.current.show({
            severity: "success",
            summary: "Success",
            detail: "DB to Mount Transfer Success",
            life: 3000,
          });
        }

        // Need to add DB to DB here .
        else if (location.pathname === "/database-transfer") {
          const axiosInstance = await myAxios();
          response = await axiosInstance.post("dbtodb/start", combinedData);

          toastTopRight.current.show({
            severity: "success",
            summary: "Success",
            detail: "DB to DB Transfer Task Added Successfully.",
            life: 3000,
          });
        }

        navigate("/home");
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        toastTopRight.current.show({
          severity: "error",
          summary: "Token Expired ",
          detail: "Try Login Again!",
          life: 2000,
        });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.error(e);
      }
    }

    setOpenDialog(false);
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={isEditMode ? "Update" : "Done"}
        icon="pi pi-check"
        onClick={handleSubmit}
        autoFocus
      />
    </div>
  );

  return (
    <>
      <Toast ref={toastTopRight} position="top-right" />
      <Dialog
        header="Define Recurrence"
        visible={openDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!openDialog) return;
          setOpenDialog(false);
        }}
        footer={footerContent}
      >
        <form>
          <Toast ref={toastTopCenter} position="top-center" />
          <Messages ref={toastmsg} />
          <div className="grid grid-nogutter gap-5 py-5">
            <div className="col-12">
              <div className="col-6 p-0">
                <FloatLabel>
                  <Dropdown
                    inputId="frequency_dropdown"
                    value={options.find(
                      (option) => option.id === selectedOption
                    )}
                    options={options}
                    onChange={(e) => setSelectedOption(e.value.id)}
                    placeholder="Choose Frequency"
                    optionLabel="label"
                    className="w-full"
                  />
                  <label htmlFor="frequency_dropdown">Frequency</label>
                </FloatLabel>
              </div>
            </div>

            <>
              {(selectedOption === "hoursMinutes" ||
                selectedOption === "days" ||
                selectedOption === "weeks" ||
                selectedOption === "months") && (
                <div className="grid col-12 grid-nogutter gap-5">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                    <FloatLabel>
                      <InputNumber
                        id="hoursInput"
                        inputId="hoursInput"
                        value={values.hours}
                        onValueChange={(e) => handleChange(e, "hours")}
                        mode="decimal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        className="w-80px"
                      />
                      <label htmlFor="hoursInput">Hour(s)</label>
                    </FloatLabel>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                    <FloatLabel>
                      <InputNumber
                        id="minutesInput"
                        inputId="minutesInput"
                        value={values.minutes}
                        onValueChange={(e) => handleChange(e, "minutes")}
                        mode="decimal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        className="w-80px"
                      />
                      <label htmlFor="minutesInput">Minute(s)</label>
                    </FloatLabel>
                  </div>
                </div>
              )}
              {(selectedOption === "days" ||
                selectedOption === "weeks" ||
                selectedOption === "months") && (
                <div className="grid col-12 grid-nogutter ">
                  <div className="col-3 p-0">
                    <FloatLabel>
                      <InputNumber
                        id="daysInput"
                        inputId="daysInput"
                        value={values.days}
                        onValueChange={(e) => handleChange(e, "days")}
                        mode="decimal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        className="w-80px"
                      />
                      <label htmlFor="daysInput">Day(s)</label>
                    </FloatLabel>
                  </div>
                </div>
              )}
              {(selectedOption === "weeks" || selectedOption === "months") && (
                <div className="grid col-12 grid-nogutter ">
                  <div className="col-3 p-0">
                    <FloatLabel>
                      <InputNumber
                        id="weeksInput"
                        inputId="weeksInput"
                        value={values.weeks}
                        onValueChange={(e) => handleChange(e, "weeks")}
                        mode="decimal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        className="w-80px"
                      />
                      <label htmlFor="weeksInput">Week(s)</label>
                    </FloatLabel>
                  </div>
                </div>
              )}
              {selectedOption === "months" && (
                <div className="grid col-12 grid-nogutter ">
                  <div className="col-3 p-0">
                    <FloatLabel>
                      <InputNumber
                        id="monthsInput"
                        inputId="monthsInput"
                        value={values.months}
                        onValueChange={(e) => handleChange(e, "months")}
                        mode="decimal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                      />
                      <label htmlFor="monthsInput">Month(s)</label>
                    </FloatLabel>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="grid col-12 grid-nogutter gap-5">
            <div className="col-5">
              <FloatLabel>
                <Calendar
                  id="startDate"
                  inputId="startDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={today}
                  showIcon
                  showButtonBar
                  placeholder="mm/dd/yyyy"
                />
                <label htmlFor="startDate">From</label>
              </FloatLabel>
            </div>
            <div className="col-5">
              <FloatLabel>
                <Calendar
                  id="endDate"
                  inputId="endDate"
                  minDate={startDate || today}
                  value={endDate}
                  onChange={handleEndDateChange}
                  showIcon
                  showButtonBar
                  placeholder="mm/dd/yyyy"
                />
                <label htmlFor="endDate">To</label>
              </FloatLabel>
            </div>
          </div>

          <br></br>
          <div className="grid col-12 grid-nogutter gap-5">
            <div className="col-5">
              <FloatLabel>
                <Calendar
                  id="timeInput"
                  value={selectedTime}
                  onChange={(e) =>
                    setSelectedTime(
                      e.value
                        ? new Date(
                            e.value.getTime() - e.value.getSeconds() * 1000
                          )
                        : null
                    )
                  }
                  timeOnly
                  hourFormat="24"
                  showIcon
                  showTime
                  placeholder="HH:mm"
                  mask="99:99"
                />
                <label htmlFor="endDate">Time 24-hour format</label>
              </FloatLabel>
            </div>
            <div className="col-5">
              <FloatLabel>
                <Dropdown
                  value={options1.find((option) => option.label === timeZone)}
                  options={options1}
                  onChange={(e) => handleSelect1(e.value)}
                  placeholder="Time Zone"
                  optionLabel="label"
                  style={{ width: "300px" }}
                />
                <label htmlFor="endDate">Time Zone</label>
              </FloatLabel>
              <small className="text-xs">
                You must start up the schedule for these settings to take effect
              </small>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default TimeScheduling;
