import loadConfig from "../../../../config";

export const Signup = async () => {
  const config = await loadConfig();

  const registerCustomer = (data) => {
    return fetch(`${config.pythonBaseURL}/user/signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    }).then((res) => res.json());
  };

  return registerCustomer;
};
