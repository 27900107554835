
let toastRef = null;

const setToastRef = (ref) => {
  toastRef = ref;
};

const showToast = (severity, summary, detail) => {
    if (toastRef) {
      toastRef.show({ severity, summary, detail, life: 3000 });
    }
};
  
const toastService = {
    setToastRef,
    showToast,
    success: (summary, detail) => showToast('success', summary, detail),
    error: (summary, detail) => showToast('error', summary, detail),
    info: (summary, detail) => showToast('info', summary, detail),
    warn: (summary, detail) => showToast('warn', summary, detail),
};
  
export default toastService;