import React, { useState, useContext, useRef, useEffect } from "react";
import { ContextTimeScheduling } from "../components/scheduleComponent/ContextTimeScheduling";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Breadcrumbs from "../components/Breadcrumb";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // Core Styles
import "primeicons/primeicons.css"; // Icons
import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

export default function SftpTransferFile() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const location = useLocation();
  const { response } = location.state || {};

  const [checked, setChecked] = useState(false);
  ///Here state for test connection
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  const { setOpenDialog, setcontextFormData } = useContext(
    ContextTimeScheduling
  );
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedSftpOption2, setSelectedSftpOption2] = useState("");

  const [formData, setFormData] = useState({
    server1: "",
    port1: "",
    sftpUser1: "",
    sftpPass1: "",
    file1path: "",
    fileName: "",
    isfolder: false,
    server2: "",
    port2: "",
    sftpUser2: "",
    sftpPass2: "",
    file2path: "",
    jobname: "",
    archiveLocation: "",
  });

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  // added for test connection for sftp1

  const [sftpServer1, setSftpServer1] = useState({
    server1: "",
    port1: "",
    user1: "",
    pass1: "",
    filePath: "",
  });

  //Dropdown
  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = response.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      setFormData((prevState) => ({
        ...prevState,
        server1: selectedData.data.find((e) => e.name === "host")?.value || "",
        port1: selectedData.data.find((e) => e.name === "port")?.value || "",
        sftpUser1:
          selectedData.data.find((e) => e.name === "username")?.value || "",
        sftpPass1:
          selectedData.data.find((e) => e.name === "password")?.value || "",
      }));
    }
  };

  const handleSftpSelectChange2 = (event) => {
    const selectedName2 = event.target.value;
    setSelectedSftpOption2(selectedName2);

    const selectedData2 = response.find(
      (item) => item.name === selectedName2.name
    );
    if (selectedData2) {
      setFormData((prevState) => ({
        ...prevState,
        server2: selectedData2.data.find((e) => e.name === "host")?.value || "",
        port2: selectedData2.data.find((e) => e.name === "port")?.value || "",
        sftpUser2:
          selectedData2.data.find((e) => e.name === "username")?.value || "",
        sftpPass2:
          selectedData2.data.find((e) => e.name === "password")?.value || "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (checked) {
      formData.fileName = "";
      formData.isfolder = true;
    } else {
      formData.isfolder = false;
    }
  }, [checked]);

  const validateForm = () => {
    if (!checked) {
      for (const key in formData) {
        if (formData[key] === "") {
          return false;
        }
      }
    } else {
      for (const key in formData) {
        if (key !== "fileName" && formData[key] === "") {
          return false;
        }
      }
    }
    return true; // All fields are filled
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all fields.",
        life: 3000,
      });
    }
  };

  //useEffect on outside
  useEffect(() => {
    if (sftpServer1) {
      if (checkSftpValid()) {
        setLoading(true);
        setVisible(true);
        callTestSFTP();
      }
    }
  }, [sftpServer1]);

  const checkSftpValid = () => {
    for (const key in sftpServer1) {
      if (sftpServer1[key] === "") {
        return false; // Return false if any field is empty
      }
    }
    return true;
  };

  const callTestSFTP = async () => {
    try {
      const axiosInstance = await myAxios();
      let sftp_connect = await axiosInstance
        .post("/test/con/sftp", sftpServer1)
        .then((res) => res.data);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);
    } catch (e) {
      console.log(e);

      let errorResponse = e.response ? e.response : null;
      if (errorResponse == null) {
        setResponseMessage("Server Link Failed");
        setResponseStatus("500");

        setTimeout(() => {
          localStorage.clear();
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Server Link Failure",
          life: 2000,
        });

        return;
      } else if (errorResponse.status === 401) {
        setResponseMessage("Session Expired ");
        setResponseStatus("401");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Session Timeout",
          detail: "Session Expired!, Try Login Again.",
          life: 2000,
        });
        return;
      } else {
        setResponseMessage(errorResponse.data.messages || "Failed to connect.");
        setResponseStatus(errorResponse.data.status || "Error");
      }
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (!checked) {
        if (formData.fileName.trim() === "") {
          toast.current.show({
            severity: "error",
            summary: "File Name",
            detail: "Please Provide the fileName.",
            life: 3000,
          });
          return;
        }
      }
      if (
        formData.file1path.trim() === "" ||
        formData.server1.trim() === "" ||
        formData.port1.trim() === "" ||
        formData.sftpUser1.trim() === "" ||
        formData.sftpPass1.trim() === ""
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Source's required fields .",
          life: 3000,
        });
        return;
      } else {
        setSftpServer1({
          server1: formData.server1,
          port1: formData.port1,
          user1: formData.sftpUser1,
          pass1: formData.sftpPass1,
          filePath: formData.file1path + "/" + formData.fileName,
        });
      }
    }
    // for second button
    else {
      if (
        formData.file2path.trim() === "" ||
        formData.server2.trim() === "" ||
        formData.port2.trim() === "" ||
        formData.sftpUser2.trim() === "" ||
        formData.sftpPass2.trim() === ""
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Target's required fields .",
          life: 3000,
        });
        return;
      } else {
        setSftpServer1({
          server1: formData.server2,
          port1: formData.port2,
          user1: formData.sftpUser2,
          pass1: formData.sftpPass2,
          filePath: formData.file2path,
        });
      }
    }
  };

  return (
    <>
      <div>
        <Breadcrumbs />
      </div>
      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 sm:w-8 m-auto p-3">
          <div className="grid">
            <div className="col-12">
              <span className="text-2xl font-bold">
                Mount to Mount Transfer
              </span>
            </div>

            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name "
                  required
                />
              </div>
            </div>

            <div className="col-12 sm:col-2"></div>

            <div className="col-12 sm:col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={response || []}
                      optionLabel="name"
                      showClear
                      placeholder="Select a Source SFTP"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="file1path"
                      className="w-full"
                      value={formData.file1path}
                      onChange={handleChange}
                      placeholder="Enter Source Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="isfolder"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      All Files in the Folder
                    </label>
                    <Checkbox
                      inputId="isfolder"
                      name="isfolder"
                      value={formData.checked}
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                      disabled={checked}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="archiveLocation_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2"></div>
            <div className="col-12 sm:col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption2 || null} // Ensuring selectedSftpOption is properly initialized
                      onChange={handleSftpSelectChange2}
                      options={response || []} // Default to an empty array if response is null or undefined
                      optionLabel="name"
                      showClear
                      placeholder="Select a Target SFTP"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path2_input"
                      name="file2path"
                      className="w-full"
                      value={formData.file2path}
                      onChange={handleChange}
                      placeholder="Enter Target Path"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 2)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <Dialog
                  header="Connection Test"
                  visible={visible}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible(false)}
                  footer={footerContent}
                >
                  <Card>
                    {loading ? (
                      <div className="card flex justify-content-center">
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="5"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <>
                        <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                        <p className="m-0">Message: {responseMessage}</p>
                      </>
                    )}
                  </Card>
                </Dialog>
              </div>
            </div>

            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>
        </form>
      </div>
      <Toast ref={toast} />
    </>
  );
}
