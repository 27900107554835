import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import logo from "../../assets/logo.svg";
import { Signin } from "../service/signin";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { Toast } from "primereact/toast";

export function SigninComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);



  useEffect(() => {
    if (
      localStorage.getItem("id") !== null &&
      localStorage.getItem("user") !== null
    ) {
      let id = localStorage.getItem("id");
      let user = localStorage.getItem("user");
      dispatch(authActions.setAuth({ id: id, user: user }));
    }
  }, [dispatch]);

  const auth = useSelector((state) => state.auth.login);
  const [user, setUser] = useState({
    user: "",
    password: "",
  });

  if (auth) {
    return navigate("/document-review/home");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let networkTimeout = null;
  const SubmitData = () => {
    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    networkTimeout = setTimeout(async () => {
      const signIn = await Signin();
      signIn(JSON.stringify(user)).then((data) => {
        if (data.status === 200) {
          localStorage.setItem("id", data.id);
          localStorage.setItem("user", data.user);
          localStorage.setItem("password",data.password)
          dispatch(authActions.setAuth({ id: data.id, user: data.user }));

          // Show success toast if authentication is successful
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Signin successful",
            life: 3000,
          });

          // Delay navigation to ensure toast is shown
          setTimeout(() => {
            navigate("/document-review/home");
          }, 3000); // 3 seconds delay for the toast
        } else {
          // Show error toast if authentication fails
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Authentication failed",
            life: 3000,
          });
        }
      });
    }, Math.random() * 1000 + 250);
  };

  const handleCreate = () => {
    return navigate("/document-review/signup");
  };

//   return (
//     <div
//       className="flex align-items-center justify-content-center"
//       style={{ marginTop: "100px" }}
//     >
//       <Toast ref={toast} />
//       <Card
//         className="w-full login-card"
//         style={{ maxWidth: "450px", width: "100%" }}
//       >
//         <div className="flex flex-column gap-3 align-items-center">
//           <img
//             alt="logo"
//             src={logo}
//             height="40"
//             className="mr-2"
//             style={{ marginLeft: "15px" }}
//           />
//           <h3>Vaanara Technologies</h3>
//           <InputText
//             type="email"
//             className="p-inputtext-lg"
//             placeholder="Email"
//             value={user.user}
//             name="user"
//             onChange={handleInputChange}
//             style={{ width: "100%" }}
//           />
//           <InputText
//             type="password"
//             className="p-inputtext-lg"
//             placeholder="Password"
//             value={user.password}
//             name="password"
//             onChange={handleInputChange}
//             style={{ width: "100%" }}
//           />
//           <Button
//             label="Signin"
//             icon="pi pi-check"
//             size="large"
//             onClick={SubmitData}
//             style={{ width: "100%" }}
//           />
//         </div>
//         <p
//           onClick={handleCreate}
//           style={{
//             cursor: "pointer",
//             textDecoration: "underline",
//             textAlign: "center",
//             marginTop: "20px",
//           }}
//         >
//           Create Account
//         </p>
//       </Card>
//     </div>
//   );
}