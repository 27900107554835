import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import mounttomount from "./images/mounttomount.png";
import mounttodb from "./images/mounttodb.png";
import dbtomount from "./images/dbtomount.png";
import dbToDb from "./images/dbToDb.png";
import "./createrule.css";
import Breadcrumbs from "../Breadcrumb";
import { toast } from "react-toastify";
import { createAxiosInstance as myAxios } from "../../services/Helper";

export default function Createrule() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Handle Mount To Mount Click
  const handleMountToMountClick = async () => {
    setLoading(true); // Set loading state to true

    // let jwt = localStorage.getItem("accessToken");

    const axiosInstance = await myAxios();

    const response = await axiosInstance
      .get(`/secrets/get-by-company/get?company=vaanaratechnologies&type=sftp`)
      .then((res) => res.data)
      .catch((e) => e.response);

    setLoading(false);
    if (response.status !== undefined && response.status === 401) {
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }

    navigate("/sftp-transfer", { state: { response } });
  };

  // Handle Mount To Database Click (SFTP To Database)
  const handleMountToDatabaseClick = async () => {
    setLoading(true); // Set loading state to true

    // let jwt = localStorage.getItem("accessToken");

    // try {
    // Fetch SFTP data

    const axiosInstance = await myAxios();

    


    const sftpResponse = await axiosInstance.get(
      `/secrets/get-by-company/get?company=vaanaratechnologies&type=sftp`,
    )
      .then((res) => res.data)
      .catch((e) => e.response);

    if (sftpResponse.status !== undefined && sftpResponse.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }
    // Fetch Database data



    const dbResponse = await axiosInstance.get(
      `/secrets/get-by-company/get?company=vaanaratechnologies&type=db`,
    )
      .then((res) => res.data)
      .catch((e) => e.response);
    if (dbResponse.status !== undefined && dbResponse.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }

    setLoading(false);
    // Navigate to the appropriate page with both responses
    navigate("/sftp-database", {
      state: { sftpResponse, dbResponse },
    });
  };

  // Handle Database To Mount Click (Database To SFTP)
  const handleDatabaseToMountClick = async () => {
    setLoading(true); // Set loading state to true



    // try {
    // Fetch Database data
        const axiosInstance = await myAxios();

    const dbResponse = await axiosInstance.get(
      `/secrets/get-by-company/get?company=vaanaratechnologies&type=db`,
    )
      .then((res) => res.data)
      .catch((e) => e.response);

    if (dbResponse.status !== undefined && dbResponse.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }

    // Fetch SFTP data
    const sftpResponse = await axiosInstance.get(
      `/secrets/get-by-company/get?company=vaanaratechnologies&type=sftp`,
    )
      .then((res) => res.data)
      .catch((e) => e.response);

    if (sftpResponse.status !== undefined && sftpResponse.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }

    setLoading(false);
    // Navigate to the appropriate page with both responses
    navigate("/database-sftp", {
      state: { dbResponse, sftpResponse },
    });
  };

  // Handle Database To Database Click (Database)
  const handleDatabaseToDatabaseClick = async () => {
    setLoading(true); // Set loading state to true



    // try {
    // Fetch Database data
        const axiosInstance = await myAxios();

    const response = await axiosInstance.get(
      `/secrets/get-by-company/get?company=vaanaratechnologies&type=db`,
    )
      .then((res) => res.data)
      .catch((e) => e.response);

    setLoading(false);
    if (response.status !== undefined && response.status === 401) {
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }
    // Navigate to the appropriate page with Database response
    navigate("/database-transfer", { state: { response } });
  };

  return (
    <>
      {/* Show spinner when loading */}
      {loading && (
        <div className="loading-overlay">
          <ProgressSpinner style={{ width: "50px", height: "50px" }} />
        </div>
      )}

      <div style={{ marginLeft: "5px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>

      <div className="container-cust min-h-screen">
        <div className="flex flex-row flex-wrap w-full justify-content-center align-items-center gap-3 h-full py-5">
          <div className="w-12 md:w-3 cursor-pointer">
            <div
              className="rule-box-3 flex flex-column gap-4 p-4 border-round-xl bg-white shadow-2 hover:shadow-6 text-center"
              onClick={handleMountToMountClick}
            >
              <div className="iconimage">
                <img src={mounttomount} alt="icon" className="src" />
              </div>
              <span className="servicename text-xl font-bold">
                Mount To Mount
              </span>
              <p className="text-base">
                will transfer file from one Mount Location to another Mount
                location.
              </p>
            </div>
          </div>
          <div className="w-12 md:w-3 cursor-pointer">
            <div
              className="rule-box-3 flex flex-column gap-4 p-4 border-round-xl bg-white shadow-2 hover:shadow-6 text-center"
              onClick={handleMountToDatabaseClick}
            >
              <div className="iconimage">
                <img src={mounttodb} alt="icon" className="src" />
              </div>
              <span className="servicename text-xl font-bold">
                Mount To Database
              </span>
              <p className="text-base">
                Taking the Csv file and insert the details into your Database
              </p>
            </div>
          </div>
          <div className="w-12 md:w-3 cursor-pointer">
            <div
              className="rule-box-3 flex flex-column gap-4 p-4 border-round-xl bg-white shadow-2 hover:shadow-6 text-center"
              onClick={handleDatabaseToMountClick}
            >
              <div className="iconimage">
                <img src={dbtomount} alt="icon" className="src" />
              </div>
              <span className="servicename text-xl font-bold">
                Database To Mount
              </span>
              <p className="text-base">
                Retrieve your Database details into a csv file
              </p>
            </div>
          </div>
          <div className="w-12 md:w-3 cursor-pointer">
            <div
              className="rule-box-3 flex flex-column gap-4 p-4 border-round-xl bg-white shadow-2 hover:shadow-6 text-center"
              onClick={handleDatabaseToDatabaseClick}
            >
              <div className="iconimage">
                <img src={dbToDb} alt="icon" className="src" />
              </div>
              <span className="servicename text-xl font-bold">
                Database To Database
              </span>
              <p className="text-base">
                Transfer data from one database to another.
              </p>
            </div>
          </div>
          <div className="w-12 md:w-3 cursor-pointer"></div>
          <div className="w-12 md:w-3 cursor-pointer"></div>
        </div>
      </div>
    </>
  );
}
