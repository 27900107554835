import React, { useState, useEffect } from "react";
import Breadcrumbs from "../Breadcrumb";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import JobDetails from "./JobDetails";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import logIcon from "./assests/files.png";

function History() {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [dataTableValues, setDataTableValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fileForm, setFileForm] = useState({
    fileName: "",
    ruleId: "",
    archivePath: "",
  });
  const [dialogVisible, setDialogVisible] = useState(false);
  const [logContent, setLogContent] = useState(""); // Store log content
  const [consoleLogs, setConsoleLogs] = useState([]); // Update to empty array initially
  const [currentJobId, setCurrentJobId] = useState(""); // Store current job id
  //Folder
  const [folderDialogVisible, setFolderDialogVisible] = useState(false);
  const [selectedFolderData, setSelectedFolderData] = useState(null);

  const [folderFiles, setFolderFiles] = useState([]); // State to hold folder files
  const [loadingFolder, setLoadingFolder] = useState(false); // State for loading folder data
  const [folderError, setFolderError] = useState(null); // State for folder error

  const location = useLocation();
  const navigate = useNavigate(); // Hook for navigation
  const { ruleId } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = await myAxios();
        const response = await axiosInstance.get(
          `mtd/sch/archive/history/${ruleId}`
        );
        const data = response.data;
        // console.log(data);

        const formattedData = data.map((item, index) => ({
          serialNo: index + 1,
          jobName: item.jobname,
          fileName: item.filenamewithtimestamp,
          startTime: new Date(item.starttime).toLocaleString(),
          endTime: new Date(item.endtime).toLocaleString(),
          runtime: item.runtime,
          status:
            item.status === "SUCCESS"
              ? "Completed"
              : item.status === "FAILURE"
              ? "Failed"
              : "Scheduled",
          scheduledBy: item.createdby,
          updatedBy: item.updatedby,
          archivePath: item.archivepath, // Adding archivePath to the formatted data
          jobId: item.jobid, // Add jobId to the formatted data
          targetTableName: item.targetTableName,
        }));
        setDataTableValues(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ruleId]);

  const getSeverity = (status) => {
    const severityMap = {
      Completed: "success",
      Scheduled: "warning",
      Failed: "danger",
    };
    return severityMap[status] || "secondary";
  };

  const statusBodyTemplate = (rowData) => (
    <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex justify-content-end">
      <InputText
        value={globalFilterValue}
        onChange={onGlobalFilterChange}
        placeholder="Global Search"
      />
    </div>
  );

  const header = renderHeader();

  const handleFileClick = async (rowData) => {
    // console.log(rowData);
    if (rowData.fileName != null) {
      setFileForm({
        fileName: rowData.fileName,
        ruleId: ruleId,
        archivePath: rowData.archivePath,
      });
      const encodedFileName = encodeURIComponent(rowData.fileName);
      navigate(`/home/history/file/${encodedFileName}`, {
        state: {
          fileForm: {
            fileName: rowData.fileName,
            ruleId: ruleId,
            archivePath: rowData.archivePath,
          },
        },
      });
    } else if (rowData.targetTableName != null) {
      // Navigate to DatabaseDetails component with relevant data
      const encodedTablename = encodeURIComponent(rowData.targetTableName);
      // console.log("rowData", rowData.targetTableName);
      navigate(`/home/history/database/${encodedTablename}`, {
        state: {
          ruleId: ruleId,
          targetTableName: rowData.targetTableName,
        },
      });
    }
  };

  const handleLogClick = async (rowData) => {
    setCurrentJobId(rowData.jobId); // Set the current job ID for fetching logs
    try {
      const axiosInstance = await myAxios();
      const response = await axiosInstance.get(`mtdb/job1/${rowData.jobId}`);
      setConsoleLogs(response.data); // Set the console logs data
    } catch (error) {
      console.error("Error fetching logs:", error);
      setError(error.message);
    }

    // Determine log content based on fileName
    const logContentText = rowData.fileName
      ? `Logs for file: ${rowData.fileName}`
      : `Logs for Job Name: ${rowData.jobName}`;
    setLogContent(logContentText); // Set the log content
    setDialogVisible(true); // Show the dialog
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  //this is only the file name///////
  const fetchFolderFiles = async (rowData) => {
    setLoadingFolder(true);
    setFolderError(null);
    try {
      const axiosInstance = await myAxios();
      // console.log(rowData);
      const response = await axiosInstance.get(
        `folder?id=${ruleId}&folder=${encodeURIComponent(rowData.archivePath)}`
      );

      // Extract file names from paths
      // const fileArchivePath = response.data.files;
      // console.log(fileArchivePath);
      const fileNames = response.data.files.map((filePath) => {
        return filePath.substring(filePath.lastIndexOf("/") + 1);
      });
      // console.log(fileNames);

      setFolderFiles(fileNames);
    } catch (error) {
      console.error("Error fetching folder files:", error);
      setFolderError(
        error.response ? error.response.data.message : error.message
      );
    } finally {
      setLoadingFolder(false);
    }
  };

  const handleFolderClick = (rowData) => {
    // console.log("Row Data for Folder Click:", rowData);
    setSelectedFolderData(rowData); // Ensure rowData contains the needed properties
    fetchFolderFiles(rowData);
    setFolderDialogVisible(true);
  };

  const handleViewFile = async (fileName) => {
    // console.log(fileName);
    if (!selectedFolderData || !selectedFolderData.archivePath) {
      console.error("selectedFolderData or archivePath is null");
      return; // Prevent further execution
    }

    // Assuming fileName contains the name of the file you want to view
    const encodedFileName = encodeURIComponent(fileName); // Use the file name directly
    const fullPath = `${selectedFolderData.archivePath}/${fileName}`;

    navigate(`/home/history/file/${encodedFileName}`, {
      state: {
        fileForm: {
          ruleId: ruleId,
          archivePath: fullPath, // Use the full path
        },
      },
    });
  };

  return (
    <>
      <div style={{ marginLeft: "5px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <div>
        <div className="card" style={{ marginTop: "10px" }}>
          <TabView>
            <TabPanel header="Job Details">
              <JobDetails ruleId={ruleId} />
            </TabPanel>
            <TabPanel header="History">
              <DataTable
                value={dataTableValues}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                globalFilter={globalFilterValue}
                header={header}
                rowClassName={(rowData) =>
                  rowData.status === "Failed" ? "error-row" : ""
                }
              >
                <Column
                  field="serialNo"
                  header="Serial No"
                  style={{ textAlign: "center" }}
                />
                <Column
                  field="jobName"
                  header="Job Name"
                  filter
                  filterPlaceholder="Search by Job Name"
                />
                <Column
                  field="fileName"
                  header="File Name"
                  filter
                  filterPlaceholder="Search by File Name"
                />
                <Column
                  field="startTime"
                  header="Start Time"
                  filter
                  filterPlaceholder="Search by Start Time"
                />
                <Column
                  field="endTime"
                  header="End Time"
                  filter
                  filterPlaceholder="Search by End Time"
                />
                <Column
                  header="Logs"
                  body={(rowData) => (
                    <Button
                      icon={
                        <img
                          src={logIcon}
                          alt="Logs"
                          style={{ width: "1.5em", height: "1.5em" }}
                        />
                      }
                      className="p-button-rounded p-button-text"
                      onClick={() => handleLogClick(rowData)}
                    />
                  )}
                  style={{ textAlign: "center", width: "6em" }}
                />
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  filter
                  filterPlaceholder="Search by Status"
                />
                <Column
                  field="scheduledBy"
                  header="Scheduled By"
                  filter
                  filterPlaceholder="Search by Scheduled By"
                />
                <Column
                  field="updatedBy"
                  header="Updated By"
                  filter
                  filterPlaceholder="Search by Updated By"
                />
                <Column
                  header="File"
                  body={(rowData) => {
                    // Check if fileName is null and status is "Failed"
                    if (
                      rowData.fileName === null &&
                      rowData.status === "Failed"
                    ) {
                      return <span>N/A</span>;
                    }

                    const hasCSV =
                      rowData.fileName && rowData.fileName.includes(".csv");
                    const isMultipleFiles = rowData.fileName && !hasCSV; // Check if it's a non-CSV file

                    let icon;
                    if (rowData.fileName === null) {
                      icon = "pi pi-database"; // Database icon for empty fileName
                    } else if (isMultipleFiles) {
                      icon = "pi pi-folder"; // Folder icon for non-CSV files
                    } else if (hasCSV) {
                      icon = "pi pi-file"; // File icon for CSV files
                    }

                    return (
                      <Button
                        icon={icon} // Choose icon based on fileName
                        className="p-button-rounded p-button-text"
                        onClick={
                          isMultipleFiles
                            ? () => handleFolderClick(rowData)
                            : () => handleFileClick(rowData)
                        }
                      />
                    );
                  }}
                  style={{ textAlign: "left", width: "6em" }}
                />

                <Column
                  field="runtime"
                  header="Runtime"
                  filter
                  filterPlaceholder="Search by Runtime"
                />

                <Column
                  field="targetTableName"
                  header="Table Name for DB to DB"
                />
              </DataTable>
            </TabPanel>
          </TabView>
        </div>
      </div>

      {/* Dialog Component */}
      <Dialog
        header="Log Details"
        visible={dialogVisible}
        style={{ width: "80vw" }} // Adjust the width as needed
        onHide={() => setDialogVisible(false)}
      >
        <p className="m-0">{logContent}</p>
        <div className="card">
          <DataTable
            value={consoleLogs}
            scrollable
            scrollHeight="400px"
            style={{ minWidth: "50rem" }}
            rowClassName={(rowData) =>
              rowData.level === "ERROR" ? "error-row" : ""
            }
          >
            <Column field="id" header="ID" />
            <Column
              field="level"
              header="Level"
              body={(rowData) => (
                <span
                  style={{
                    color: rowData.level === "ERROR" ? "red" : "inherit",
                  }}
                >
                  {rowData.level}
                </span>
              )}
            />
            <Column
              field="message"
              header="Message"
              body={(rowData) => (
                <span
                  style={{
                    color: rowData.level === "ERROR" ? "red" : "inherit",
                  }}
                >
                  {rowData.message}
                </span>
              )}
            />
            <Column
              field="timestamp"
              header="Timestamp"
              body={(rowData) => new Date(rowData.timestamp).toLocaleString()}
            />
          </DataTable>
        </div>
      </Dialog>

      <Dialog
        header="Folder Details"
        visible={folderDialogVisible}
        onHide={() => setFolderDialogVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        {loadingFolder ? (
          <div>Loading folder files...</div>
        ) : folderError ? (
          <div>Error fetching folder files: {folderError}</div>
        ) : folderFiles.length > 0 ? (
          <DataTable
            value={folderFiles}
            scrollable
            scrollHeight="400px"
            style={{ minWidth: "50rem" }}
          >
            <Column
              field="filePath"
              header="Files In the Folder"
              body={(rowData) => rowData} // Ensure this displays the correct file name
            />
            <Column
              header="File"
              body={(fileName) => (
                <Button
                  icon="pi pi-file"
                  onClick={() => handleViewFile(fileName)} // Pass the file name directly
                  className="p-button-text"
                  aria-label="View File"
                />
              )}
            />
          </DataTable>
        ) : (
          <div>No files found in this folder.</div>
        )}
      </Dialog>
    </>
  );
}

export default History;