function Validation_Login(value) {
    const errors = {};

    if(!value.userName)
        errors.userName = "User Name is required"

    if(!value.password) 
        errors.password = "Password is required"
    return errors;
}
export default Validation_Login;