import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import logo from "../../assets/logo.svg"; // Ensure this path is correct
import { Signup } from "../service/signup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { Toast } from "primereact/toast";

export function SignupComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    if (
      localStorage.getItem("id") !== null &&
      localStorage.getItem("user") !== null
    ) {
      let id = localStorage.getItem("id");
      let user = localStorage.getItem("user");
      dispatch(authActions.setAuth({ id: id, user: user }));
    }
  }, []);

  const auth = useSelector((state) => state.auth.login);
  const [user, setUser] = useState({
    user: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  if (auth) {
    return navigate("/document-review/home");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let networkTimeout = null;
  const SubmitData = () => {
    // Check if passwords match
    if (user.password !== user.confirmPassword) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passwords do not match",
        life: 3000,
      });
      return; // Prevent form submission
    }

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }
    networkTimeout = setTimeout(async () => {
      const signUp = await Signup();
      signUp(JSON.stringify(user)).then((data) => {
        if (data.status === 201) {
          localStorage.setItem("id", data.id);
          localStorage.setItem("user", data.user);
          dispatch(authActions.setAuth({ id: data.id, user: data.user }));
          return navigate("/document-review/home");
        } else {
          // Show error toast if signup fails
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Email already exists",
            life: 3000,
          });
        }
      });
    }, Math.random() * 1000 + 250);
  };

  const handleSignIn = () => {
    navigate("/document-review/signin");
  };

  return (
    <div
      className="flex align-items-center justify-content-center"
      style={{ marginTop: "100px" }}
    >
      <Toast ref={toast} />
      <Card
        className="w-full login-card"
        style={{ maxWidth: "450px", width: "100%" }}
      >
        <div className="flex flex-column gap-3 align-items-center">
          <img
            alt="logo"
            src={logo}
            height="40"
            style={{ marginBottom: "0px" }}
          />
          <h3>Vaanara Technologies</h3>
          <InputText
            type="text"
            className="p-inputtext-lg"
            placeholder="Username"
            value={user.user}
            name="user"
            onChange={handleInputChange}
            style={{ width: "100%" }}
          />
          <InputText
            type="email"
            className="p-inputtext-lg"
            placeholder="Email"
            value={user.email}
            name="email"
            onChange={handleInputChange}
            style={{ width: "100%" }}
          />
          <InputText
            type="password"
            className="p-inputtext-lg"
            placeholder="Password"
            value={user.password}
            name="password"
            onChange={handleInputChange}
            style={{ width: "100%" }}
          />
          <InputText
            type="password"
            className="p-inputtext-lg"
            placeholder="Confirm Password"
            value={user.confirmPassword}
            name="confirmPassword"
            onChange={handleInputChange}
            style={{ width: "100%" }}
          />
          <Button
            label="Signup"
            icon="pi pi-check"
            size="large"
            onClick={SubmitData}
            style={{ width: "100%" }}
          />
          <p
            onClick={handleSignIn}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            Already have an account? Signin
          </p>
        </div>
      </Card>
    </div>
  );
}
