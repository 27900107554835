// import React, { useRef, useState, useEffect } from "react";
// import { Toast } from "primereact/toast";
// import { FileUpload } from "primereact/fileupload";
// import { ProgressBar } from "primereact/progressbar";
// import { Button } from "primereact/button";
// import { Tooltip } from "primereact/tooltip";
// import { Tag } from "primereact/tag";
// import { useDispatch, useSelector } from "react-redux";
// import { Table } from "../service/table";
// import { tableActions } from "../../store/table-slice";
// import loadConfig from "../../../../config";
// import "./upload.css";

// export default function UploadFile() {
//   const dispatch = useDispatch();
//   const fileName = useSelector((state) => state.table.name);
//   const auth = useSelector((state) => state.auth.login);
//   const profile = auth ? auth.id : "profile";
//   const toast = useRef(null);
//   const [totalSize, setTotalSize] = useState(0);
//   const fileUploadRef = useRef(null);
//   const [shape, setShape] = useState(null);
//   const [uploadUrl, setUploadUrl] = useState(""); // State to store upload URL

//   // console.log("hhhhhhhhhhhhhhhhhhhhhhhhh", uploadUrl);

//   useEffect(() => {
//     const fetchUrl = async () => {
//       const config = await loadConfig();
//       setUploadUrl(`${config.pythonBaseURL}/upload/file/${profile}/`);
//     };

//     fetchUrl();
//   }, [profile]); // Fetch URL when the profile changes

//   const onTemplateSelect = (e) => {
//     dispatch(tableActions.setName(null));
//     let _totalSize = totalSize;
//     let files = e.files;

//     Object.keys(files).forEach((key) => {
//       _totalSize += files[key].size || 0;
//     });

//     setTotalSize(_totalSize);
//   };

//   const onTemplateUpload = async (e) => {
//     for (const file of e.files) {
//       let _totalSize = totalSize + file.size;
//       setTotalSize(_totalSize);

//       toast.current.show({
//         severity: "info",
//         summary: "Success",
//         detail: `${file.name} Uploaded`,
//       });

//       const tableUpdate = await Table();
//       tableUpdate(JSON.stringify({ file: "latest", user: profile })).then(
//         (data) => {
//           if (data.status === 200) {
//             localStorage.setItem("filename", data.name);
//             dispatch(tableActions.setName(data.name));
//           } else {
//             console.log("Authentication failed");
//           }
//         }
//       );
//     }
//   };

//   const onTemplateRemove = (file, callback) => {
//     setTotalSize(totalSize - file.size);
//     callback();
//   };

//   const onTemplateClear = () => {
//     setTotalSize(0);
//   };

//   const headerTemplate = (options) => {
//     const value = (totalSize / 1000000000) * 100;
//     const formattedValue = fileUploadRef.current
//       ? fileUploadRef.current.formatSize(totalSize)
//       : "0 B";

//     return (
//       <div className={options.className}>
//         {options.chooseButton}
//         {options.uploadButton}
//         {options.cancelButton}
//         <div
//           className="flex align-items-center gap-3 ml-auto"
//           style={{ fontSize: "19px", color: "#000000" }}
//         >
//           <span>{formattedValue} / 1 GB</span>
//           <ProgressBar
//             value={value}
//             showValue={false}
//             style={{ width: "10rem", height: "12px" }}
//           />
//         </div>
//       </div>
//     );
//   };

//   const itemTemplate = (file, props) => {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const contents = e.target.result;
//       const rows = contents.split("\n");
//       const rowCount = rows.length;
//       const columnCount = rows[0].split(",").length;
//       setShape(`(${rowCount}, ${columnCount})`);
//     };

//     reader.readAsText(file);

//     return fileName ? null : (
//       <div
//         className="flex align-items-center"
//         style={{
//           fontSize: "0.8em",
//           width: "100%",
//           height: "80px",
//           position: "relative",
//         }}
//       >
//         <div
//           className="flex align-items-center"
//           style={{ position: "absolute", left: "10px" }}
//         >
//           <i
//             className="pi pi-file mt-3 p-5"
//             style={{
//               fontSize: "3em",
//               borderRadius: "50%",
//               backgroundColor: "#d0d0e2",
//               color: "#4d4d4d",
//             }}
//           ></i>
//           <span
//             className="flex flex-column text-left p-5"
//             style={{ fontSize: "16px" }}
//           >
//             {file.name} {shape}
//             <small className="p-5" style={{ fontSize: "16px" }}>
//               {new Date().toLocaleDateString()}
//             </small>
//           </span>
//         </div>
//         <Tag
//           value={props.formatSize}
//           severity="warning"
//           className="px-3 py-2 custom-tag"
//           style={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//           }}
//         />
//         <Button
//           type="button"
//           icon="pi pi-times"
//           className="p-button-outlined p-button-rounded ml-auto"
//           onClick={() => onTemplateRemove(file, props.onRemove)}
//           style={{ color: "#e60000", fontSize: "0.8em", marginRight: "10px" }}
//         />
//       </div>
//     );
//   };

//   const emptyTemplate = () => (
//     <div className="flex align-items-center flex-column">
//       <i
//         className="pi pi-file mt-2 p-2"
//         style={{
//           fontSize: "2em",
//           borderRadius: "50%",
//           backgroundColor: "#d0d0e2",
//           color: "#4d4d4d",
//         }}
//       ></i>
//       <span style={{ fontSize: "1em", color: "#000000" }} className="my-2">
//         Drag and Drop CSV Here
//       </span>
//     </div>
//   );

//   const chooseOptions = {
//     icon: "pi pi-fw pi-file",
//     iconOnly: true,
//     className:
//       "custom-choose-btn p-button-rounded p-button-outlined custom-file",
//   };
//   const uploadOptions = {
//     icon: "pi pi-fw pi-cloud-upload",
//     iconOnly: true,
//     className:
//       "custom-upload-btn p-button-success p-button-rounded p-button-outlined custom-uploader",
//   };
//   const cancelOptions = {
//     icon: "pi pi-fw pi-times",
//     iconOnly: true,
//     className:
//       "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined custom-cancel",
//   };

//   return (
//     <div>
//       <Toast ref={toast}></Toast>
//       <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
//       <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
//       <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

//       <FileUpload
//         ref={fileUploadRef}
//         name="file"
//         url={uploadUrl} // Use the state variable here
//         multiple
//         accept="csv/*"
//         maxFileSize={10000000000}
//         onUpload={onTemplateUpload}
//         onSelect={onTemplateSelect}
//         onError={onTemplateClear}
//         onClear={onTemplateClear}
//         headerTemplate={headerTemplate}
//         itemTemplate={itemTemplate}
//         emptyTemplate={emptyTemplate}
//         chooseOptions={chooseOptions}
//         uploadOptions={uploadOptions}
//         cancelOptions={cancelOptions}
//       />
//     </div>
//   );
// }
import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../service/table";
import { tableActions } from "../../store/table-slice";
import loadConfig from "../../../../config";
import "./upload.css";

export default function UploadFile() {
  const dispatch = useDispatch();
  const fileName = useSelector((state) => state.table.name);
  const auth = useSelector((state) => state.auth.login);
  const profile = auth ? auth.id : "profile";
  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [shape, setShape] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(""); // State to store upload URL

  // console.log("hhhhhhhhhhhhhhhhhhhhhhhhh", uploadUrl);

  useEffect(() => {
    const fetchUrl = async () => {
      const config = await loadConfig();
      setUploadUrl(`${config.pythonBaseURL}/upload/file/${profile}/`);
    };

    fetchUrl();
  }, [profile]); // Fetch URL when the profile changes

  const onTemplateSelect = (e) => {
    dispatch(tableActions.setName(null));
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = async (e) => {
    for (const file of e.files) {
      let _totalSize = totalSize + file.size;
      setTotalSize(_totalSize);

      toast.current.show({
        severity: "info",
        summary: "Success",
        detail: `${file.name} Uploaded`,
      });

      const tableUpdate = await Table();
      tableUpdate(JSON.stringify({ file: "latest", user: profile })).then(
        (data) => {
          if (data.status === 200) {
            localStorage.setItem("filename", data.name);
            dispatch(tableActions.setName(data.name));
          } else {
            console.log("Authentication failed");
          }
        }
      );
    }
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const value = (totalSize / 1000000000) * 100;
    const formattedValue = fileUploadRef.current
      ? fileUploadRef.current.formatSize(totalSize)
      : "0 B";

    return (
      <div className={options.className}>
        {options.chooseButton}
        {options.uploadButton}
        {options.cancelButton}
        <div
          className="flex align-items-center gap-3 ml-auto"
          style={{ fontSize: "19px", color: "#000000" }}
        >
          <span>{formattedValue} / 1 GB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          />
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const contents = e.target.result;
      const rows = contents.split("\n");
      const rowCount = rows.length;
      const columnCount = rows[0].split(",").length;
      setShape(`(${rowCount}, ${columnCount})`);
    };

    reader.readAsText(file);

    return fileName ? null : (
      <div
        className="flex align-items-center"
        style={{
          fontSize: "0.8em",
          width: "100%",
          height: "80px",
          position: "relative",
        }}
      >
        <div
          className="flex align-items-center"
          style={{ position: "absolute", left: "10px" }}
        >
          <i
            className="pi pi-file mt-3 p-5"
            style={{
              fontSize: "3em",
              borderRadius: "50%",
              backgroundColor: "#d0d0e2",
              color: "#4d4d4d",
            }}
          ></i>
          <span
            className="flex flex-column text-left p-5"
            style={{ fontSize: "16px" }}
          >
            {file.name} {shape}
            <small className="p-5" style={{ fontSize: "16px" }}>
              {new Date().toLocaleDateString()}
            </small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2 custom-tag"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
          style={{ color: "#e60000", fontSize: "0.8em", marginRight: "10px" }}
        />
      </div>
    );
  };

  const emptyTemplate = () => (
    <div className="flex align-items-center flex-column">
      <i
        className="pi pi-file mt-2 p-2"
        style={{
          fontSize: "2em",
          borderRadius: "50%",
          backgroundColor: "#d0d0e2",
          color: "#4d4d4d",
        }}
      ></i>
      <span style={{ fontSize: "1em", color: "#000000" }} className="my-2">
        Drag and Drop CSV Here
      </span>
    </div>
  );

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className:
      "custom-choose-btn p-button-rounded p-button-outlined custom-file",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined custom-uploader",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined custom-cancel",
  };

  return (
    <div>
      <Toast ref={toast}></Toast>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <FileUpload
        ref={fileUploadRef}
        name="file"
        url={uploadUrl} // Use the state variable here
        multiple
        accept="csv/*"
        maxFileSize={10000000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
      />
    </div>
  );
}
