import axios from "axios";
import loadConfig from "../config";


/**
 * @author sujit
 */



// Function to create axios instance dynamically
export const createAxiosInstance = async () => {
  const config = await loadConfig(); // Load baseURL from config.json

  const myAxios = axios.create({
    baseURL: config.baseURL, // Dynamically loaded baseURL
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
  });



// export const myAxios = axios.create({
//   // baseURL: "http://localhost:8080/api",
//   // baseURL:  process.env.React_App_BASE_URL,
//   baseURL:  process.env.React_App_BASE_URL,
//   timeout: 30000,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// Add a request interceptor to include the JWT token in the Authorization header
myAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken"); // Assuming you store your token in localStorage
    if (token) {
      // console.log(token);
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



// response
myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log("i am at response intercept ");
    // console.log(error.response.status);
    let statusCode = error.response ? error.response.status: null ;
    if (statusCode === 401) {
      console.log("You are UnAuthorized so go back to login ");
      localStorage.clear();
    } else if (statusCode === 403) {
      // console.log("You are Forbidden to stay here please login first  ");
      // console.log(localStorage.getItem("accessToken"));
    }

    throw error;
  }
);

return myAxios; 


}
//export default myAxios;
