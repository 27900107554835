import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./RuleList.css";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { format } from "date-fns";
import { Tooltip } from "primereact/tooltip";

let checkStatus = (active) => {
  switch (active) {
    case 0:
      return "Not Started ";
    case 1:
      return "Started ";
    case 2:
      return "Pause ";
    case 3:
      return "Resume ";
    case 4:
      return "Deleted ";
    default:
      return "Stopped ";
  }
};

const confirm1 = () => {
  confirmDialog({
    group: "headless",
    message: "Are you sure you want to Delete Now ?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    defaultFocus: "accept",
  });
};

//rule list
const RuleList = ({ rules, setRules }) => {
  const navigate = useNavigate();

  // State to manage actions for each rule
  const [ruleActions, setRuleActions] = useState({});
  const [ruleStatus, setRuleStatus] = useState({});
  const [currentRuleId, setCurrentRuleId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // fetch rules
  const fetchRules = async () => {
    const axiosInstance = await myAxios();
    let data = await axiosInstance
      .get("hmtm/rulelist")
      .then((res) => res.data)
      .then((finalRes) => {
        setRules(finalRes);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          navigate("/login");
        } else {
          console.error(e);
        }
      });
  };

  useEffect(() => {
    fetchRules();
  }, []);

  const handleStart = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "started" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Started" });
    const ruleCode = ruleId.substring(0, 3);
    const axiosInstance = await myAxios();
    await axiosInstance
      .post(`${ruleCode}/sch/start/${ruleId}`)
      .then((res) => res.data)
      .then((fres) => console.log(fres))
      .catch((e) => console.error(e));

    fetchRules();
  };

  const handleStop = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "stopped" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Stopped" });
    const ruleCode = ruleId.substring(0, 3);
    const axiosInstance = await myAxios();
    await axiosInstance
      .post(`${ruleCode}/sch/stop/${ruleId}`)
      .then((res) => res.data)
      .then((fres) => console.log(fres))
      .catch((e) => console.error(e));

    fetchRules();
  };

  const handlePause = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "paused" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Paused" });
    const ruleCode = ruleId.substring(0, 3);
    const axiosInstance = await myAxios();
    await axiosInstance
      .post(`${ruleCode}/sch/pause/${ruleId}`)
      .then((res) => res.data)
      .then((fres) => console.log(fres))
      .catch((e) => console.error(e));

    fetchRules();
  };

  const handleResume = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "resumed" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Resumed" });
    const ruleCode = ruleId.substring(0, 3);
    const axiosInstance = await myAxios();
    await axiosInstance
      .post(`${ruleCode}/sch/resume/${ruleId}`)
      .then((res) => res.data)
      .then((fres) => console.log(fres))
      .catch((e) => console.error(e));

    fetchRules();
  };

  const handleDelete = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "delete" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Deleted" });
    const ruleCode = ruleId.substring(0, 3);
    const axiosInstance = await myAxios();
    await axiosInstance
      .delete(`${ruleCode}/sch/delete/${ruleId}`)
      .then((res) => res.data)
      .then((fres) => console.log(fres))
      .catch((e) => console.error(e));

    fetchRules();
  };

  const renderActionButtons = (rowData) => {
    const { ruleId, active } = rowData;
    const action = ruleActions[ruleId];

    switch (active) {
      case 0: // Not Started
        return (
          <Button
            tooltip="Start"
            tooltipOptions={{ position: "bottom" }}
            className="i-20px"
            onClick={() => handleStart(ruleId)}
            style={{ fontSize: "1rem" }}
            icon="pi pi-play-circle"
            rounded
            text
            severity="danger"
            aria-label="Start"
          />
        );
      case 1:
      case 3: // Started
        return (
          <>
            <Button
              tooltip="Stop"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-stop-circle"
              onClick={() => handleStop(ruleId)}
              rounded
              text
              severity="danger"
              aria-label="Stop"
            />
            <Button
              tooltip="Pause"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-pause-circle"
              onClick={() => handlePause(ruleId)}
              rounded
              text
              severity="danger"
              aria-label="Pause"
            />
          </>
        );
      case 2: // Paused
        return (
          <>
            <Button
              tooltip="Stop"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-stop-circle"
              onClick={() => handleStop(ruleId)}
              rounded
              text
              severity="danger"
              aria-label="Stop"
            />
            <Button
              tooltip="Resume"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-circle-fill"
              onClick={() => handleResume(ruleId)}
              rounded
              text
              severity="success"
              aria-label="Resume"
            />
          </>
        );
      case 4: // Deleted
        return null; // or any specific UI for deleted rules
      default:
        return (
          <Button
            tooltip="Start"
            tooltipOptions={{ position: "bottom" }}
            className="i-20px"
            onClick={() => handleStart(ruleId)}
            icon="pi pi-play-circle"
            rounded
            text
            severity="danger"
            aria-label="Start"
          />
        );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rules
    .filter((rule) => rule.active !== 4)
    .map((rule, idx) => ({
      ...rule,
      ruleID: (currentPage - 1) * itemsPerPage + idx + 1,
      createdTime: format(rule.createdTime, "dd/MM/yyyy HH:mm:ss"),
      modifiedTime: format(rule.modifiedTime, "dd/MM/yyyy HH:mm:ss"),
      status: ruleStatus[rule.status]
        ? ruleStatus[rule.status]
        : checkStatus(rule.active), // status new_fix
    }));
  // .slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    modifiedTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdby: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    updatedby: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [statuses] = useState([
    "Stopped",
    "Started",
    "Pause",
    "Resume",
    "Deleted",
  ]);

  const getSeverity = (status) => {
    switch (status) {
      case "Stopped":
        return "danger";

      case "Started":
        return "success";

      case "Resume":
        return "info";

      case "Pause":
        return "warning";

      case "Deleted":
        return null;
    }
  };

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);

      return d;
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const gotoCreatePost = () => {
    navigate("/rules/create");
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end flex-row align-items-center gap-3">
        <Button
          onClick={() => gotoCreatePost()}
          severity="primary"
          size="small"
          className="border-round-lg"
          tooltip="Create Job"
          tooltipOptions={{ position: "bottom" }}
          label="Create Job"
          icon="pi pi-plus"
        />
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="p-inputtext-sm"
          />
        </IconField>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt="flag"
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flag flag-${rowData.country.code}`}
          style={{ width: "24px" }}
        />
        <span>{rowData.country.name}</span>
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;

    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`}
          width="32"
        />
        <span>{representative.name}</span>
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        severity={getSeverity(rowData.status?.trim())}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex flex-row align-items-center justify-content-end gap-2">
          {renderActionButtons(rowData)}
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-pencil"
            rounded
            text
            severity="secondary"
            aria-label="Edit"
            onClick={() => handleEditClick(rowData.ruleId)}
          />
          <Button
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            aria-label="Delete"
            onClick={() => {
              setCurrentRuleId(rowData.ruleId);
              confirm1();
            }}
          />{" "}
          {/**new_fix */}
        </div>
      </>
    );
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const verifiedBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames("pi", {
          "true-icon pi-check-circle": rowData.verified,
          "false-icon pi-times-circle": !rowData.verified,
        })}
      ></i>
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const verifiedRowFilterTemplate = (options) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const header = renderHeader();

  //this is for edit page
  const handleEditClick = (ruleId) => {
    const ruleCode = ruleId.substring(0, 3);
    navigate(`/${ruleCode}/rules/edit`, { state: { ruleId } });
  };

  const handleHistory = (rowData) => {
    const ruleId = rowData.ruleId;
    navigate("/home/history", { state: { ruleId } });
  };

  return (
    <>
      <DataTable
        value={currentItems}
        paginator
        rowsPerPageOptions={[10, 50, 100, 500]}
        rows={itemsPerPage}
        dataKey="id"
        filters={filters}
        filterDisplay="row"
        loading={loading}
        globalFilterFields={[
          "ruleID",
          "fileName",
          "createdTime",
          "modifiedTime",
          "createdby",
          "updatedby",
          "modifiedTime",
        ]}
        header={header}
        emptyMessage="No customers found."
      >
        <Column field="ruleID" header="Sl No" style={{ minWidth: "6rem" }} />
        <Column
          header="Job Name"
          field="fileName"
          filterField="fileName"
          filter
          filterPlaceholder="Search by file"
          style={{ minWidth: "12rem", cursor: "pointer" }}
          body={(rowData) => (
            <div
              style={{
                display: "inline-block",
                padding: "0.5rem",
                borderRadius: "4px",
                transition: "background-color 0.3s",
                cursor: "pointer",
              }}
              onClick={() => handleHistory(rowData)}
              className="underline-text highlight-row"
              data-pr-tooltip="View History" // Tooltip text
            >
              {rowData.fileName}
              <hr style={{ marginTop: "2px" }}></hr>
              <Tooltip target=".highlight-row" />
            </div>
          )}
        />
        <Column
          header="Created Time"
          field="createdTime"
          filterField="createdTime"
          filter
          style={{ minWidth: "14rem" }}
        />
        <Column
          field="modifiedTime"
          header="Modified Time"
          filterMenuStyle={{ width: "14rem" }}
          filter
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="createdby"
          header="Created By"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="updatedby"
          header="Updated By"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="status"
          header="Status"
          filter
          style={{ minWidth: "6rem" }}
          body={statusBodyTemplate}
        />
        <Column
          header="Action"
          style={{ minWidth: "6rem" }}
          body={actionBodyTemplate}
        />
      </DataTable>

      {/* confirm dialog */}
      {/* here starts the confirm dialog */}
      <ConfirmDialog
        group="headless"
        content={({ headerRef, contentRef, footerRef, hide, message }) => (
          <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
              <i className="pi pi-exclamation-triangle text-5xl"></i>
            </div>
            <span
              className="font-bold text-2xl block mb-2 mt-4"
              ref={headerRef}
            >
              {message.header}
            </span>
            <p className="mb-0" ref={contentRef}>
              {message.message}
            </p>
            <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>
              <Button
                label="Yes"
                severity="danger"
                onClick={(event) => {
                  hide(event);
                  handleDelete(currentRuleId);
                }}
                className="w-8rem"
              ></Button>
              <Button
                label="No"
                outlined
                onClick={(event) => {
                  hide(event);
                  setCurrentRuleId(null);
                }}
                className="w-8rem"
              ></Button>
            </div>
          </div>
        )}
      />
    </>
  );
};
export default RuleList;
