// // // import React, { useState } from "react";
// // // import "./CompanyRegistration.css"; // Optional custom styles
// // // import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap for styling
// // // import axios from "axios"; // Axios for HTTP requests

// // // function CompanyRegistration() {
// // //   const [formData, setFormData] = useState({
// // //     name: "", // Name field for the user
// // //     email: "",
// // //     companyName: "",
// // //     companyDesignation: "",
// // //     companyPhone: "",
// // //   });

// // //   const [companyLogo, setCompanyLogo] = useState(null); // State for handling file upload
// // //   const [otp, setOtp] = useState("");
// // //   const [isOtpSent, setIsOtpSent] = useState(false);
// // //   const [error, setError] = useState("");
// // //   const [success, setSuccess] = useState("");

// // //   // Handle form input changes for text fields
// // //   const handleInputChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setFormData({
// // //       ...formData,
// // //       [name]: value,
// // //     });
// // //   };

// // //   // Handle file input change for the company logo
// // //   const handleLogoChange = (e) => {
// // //     setCompanyLogo(e.target.files[0]); // Save the uploaded file to state
// // //   };

// // //   // Handle form submission to send data including the company logo
// // //   const handleSubmit = async (e) => {
// // //     e.preventDefault();

// // //     // Simple validation
// // //     if (
// // //       !formData.name ||
// // //       !formData.email ||
// // //       !formData.companyName ||
// // //       !formData.companyDesignation ||
// // //       !formData.companyPhone ||
// // //       !companyLogo
// // //     ) {
// // //       setError("All fields, including company logo, are required.");
// // //       return;
// // //     }

// // //     setError("");
// // //     setSuccess("");

// // //     // Prepare the form data to send to the backend
// // //     const data = new FormData();
// // //     data.append(
// // //       "user",
// // //       new Blob([JSON.stringify(formData)], { type: "application/json" })
// // //     ); // Append form data as JSON
// // //     data.append("companyLogo", companyLogo); // Append the company logo file

// // //     try {
// // //       // Send POST request to backend to register the user
// // //       const response = await axios.post(
// // //         "http://localhost:8080/api/companysignup/register",
// // //         data,
// // //         {
// // //           headers: { "Content-Type": "multipart/form-data" },
// // //         }
// // //       );

// // //       if (response.status === 200) {
// // //         setSuccess(
// // //           "OTP sent to your email. Please enter the OTP to complete registration."
// // //         );
// // //         setIsOtpSent(true); // Show the OTP input field
// // //       }
// // //     } catch (err) {
// // //       if (err.response && err.response.status === 400) {
// // //         setError(err.response.data); // Show error message from backend
// // //       } else {
// // //         console.error("Error occurred while sending OTP:", err);
// // //         setError("An error occurred. Please try again later.");
// // //       }
// // //     }
// // //   };

// // //   // Handle OTP submission
// // //   const handleOtpSubmit = async (e) => {
// // //     e.preventDefault();

// // //     if (!otp) {
// // //       setError("Please enter the OTP.");
// // //       return;
// // //     }

// // //     try {
// // //       // Send POST request to verify OTP
// // //       const response = await axios.post(
// // //         `http://localhost:8080/api/companysignup/verify?email=${formData.email}&otp=${otp}`
// // //       );

// // //       if (response.status === 200) {
// // //         setSuccess("Registration successful!");
// // //         setError("");
// // //         // Clear the form fields
// // //         setFormData({
// // //           name: "",
// // //           email: "",
// // //           companyName: "",
// // //           companyDesignation: "",
// // //           companyPhone: "",
// // //         });
// // //         setOtp("");
// // //         setCompanyLogo(null);
// // //         setIsOtpSent(false); // Hide OTP field
// // //       }
// // //     } catch (err) {
// // //       console.error("Invalid OTP:", err);
// // //       setError("Invalid OTP. Please try again.");
// // //     }
// // //   };

// // //   return (
// // //     <div className="signup-container">
// // //       <div className="signup-form">
// // //         <h3 className="text-center">Sign Up</h3>

// // //         {/* Form for registration */}
// // //         {!isOtpSent ? (
// // //           <form onSubmit={handleSubmit} encType="multipart/form-data">
// // //             <div className="form-group">
// // //               <label htmlFor="name">Name</label>
// // //               <input
// // //                 type="text"
// // //                 className="form-control"
// // //                 id="name"
// // //                 name="name"
// // //                 placeholder="Enter your name"
// // //                 value={formData.name}
// // //                 onChange={handleInputChange}
// // //                 required
// // //               />
// // //             </div>

// // //             <div className="form-group">
// // //               <label htmlFor="email">Email address</label>
// // //               <input
// // //                 type="email"
// // //                 className="form-control"
// // //                 id="email"
// // //                 name="email"
// // //                 placeholder="Enter email address"
// // //                 value={formData.email}
// // //                 onChange={handleInputChange}
// // //                 required
// // //               />
// // //             </div>

// // //             <div className="form-group">
// // //               <label htmlFor="companyName">Company Name</label>
// // //               <input
// // //                 type="text"
// // //                 className="form-control"
// // //                 id="companyName"
// // //                 name="companyName"
// // //                 placeholder="Enter company name"
// // //                 value={formData.companyName}
// // //                 onChange={handleInputChange}
// // //                 required
// // //               />
// // //             </div>

// // //             <div className="form-group">
// // //               <label htmlFor="companyDesignation">Company Designation</label>
// // //               <input
// // //                 type="text"
// // //                 className="form-control"
// // //                 id="companyDesignation"
// // //                 name="companyDesignation"
// // //                 placeholder="Enter company designation"
// // //                 value={formData.companyDesignation}
// // //                 onChange={handleInputChange}
// // //                 required
// // //               />
// // //             </div>

// // //             <div className="form-group">
// // //               <label htmlFor="companyPhone">Company Phone</label>
// // //               <input
// // //                 type="text"
// // //                 className="form-control"
// // //                 id="companyPhone"
// // //                 name="companyPhone"
// // //                 placeholder="Enter company phone"
// // //                 value={formData.companyPhone}
// // //                 onChange={handleInputChange}
// // //                 required
// // //               />
// // //             </div>

// // //             <div className="form-group">
// // //               <label htmlFor="companyLogo">Upload Company Logo</label>
// // //               <input
// // //                 type="file"
// // //                 className="form-control"
// // //                 id="companyLogo"
// // //                 name="companyLogo"
// // //                 accept="image/*"
// // //                 onChange={handleLogoChange}
// // //                 required
// // //               />
// // //             </div>

// // //             <button type="submit" className="btn btn-primary btn-block">
// // //               Register
// // //             </button>
// // //             {error && <div className="alert alert-danger mt-3">{error}</div>}
// // //             {success && (
// // //               <div className="alert alert-success mt-3">{success}</div>
// // //             )}
// // //           </form>
// // //         ) : (
// // //           /* Form for OTP submission */
// // //           <form onSubmit={handleOtpSubmit}>
// // //             <div className="form-group">
// // //               <label htmlFor="otp">Enter OTP</label>
// // //               <input
// // //                 type="text"
// // //                 className="form-control"
// // //                 id="otp"
// // //                 name="otp"
// // //                 placeholder="Enter the OTP sent to your email"
// // //                 value={otp}
// // //                 onChange={(e) => setOtp(e.target.value)}
// // //                 required
// // //               />
// // //             </div>

// // //             <button type="submit" className="btn btn-primary btn-block">
// // //               Verify OTP
// // //             </button>
// // //             {error && <div className="alert alert-danger mt-3">{error}</div>}
// // //             {success && (
// // //               <div className="alert alert-success mt-3">{success}</div>
// // //             )}
// // //           </form>
// // //         )}
// // //       </div>
// // //     </div>
// // //   );
// // // }

// // // export default CompanyRegistration;

// // import React, { useState, useRef } from "react";
// // import "./CompanyRegistration.css"; // Optional custom styles
// // import "primereact/resources/themes/saga-blue/theme.css"; // Theme for PrimeReact
// // import "primereact/resources/primereact.min.css"; // PrimeReact styles
// // import { Button } from "primereact/button";
// // import { InputText } from "primereact/inputtext";
// // import { Toast } from "primereact/toast";
// // import { Card } from "primereact/card";
// // import axios from "axios"; // Axios for HTTP requests

// // function CompanyRegistration() {
// //   const toast = useRef(null); // Toast reference
// //   const [formData, setFormData] = useState({
// //     name: "",
// //     email: "",
// //     companyName: "",
// //     companyDesignation: "",
// //     companyPhone: "",
// //   });

// //   const [companyLogo, setCompanyLogo] = useState(null);
// //   const [otp, setOtp] = useState("");
// //   const [isOtpSent, setIsOtpSent] = useState(false);
// //   const [error, setError] = useState("");
// //   const [success, setSuccess] = useState("");

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value,
// //     });
// //   };

// //   const handleLogoChange = (e) => {
// //     setCompanyLogo(e.target.files[0]);
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     if (
// //       !formData.name ||
// //       !formData.email ||
// //       !formData.companyName ||
// //       !formData.companyDesignation ||
// //       !formData.companyPhone ||
// //       !companyLogo
// //     ) {
// //       setError("All fields, including company logo, are required.");
// //       return;
// //     }

// //     setError("");
// //     setSuccess("");

// //     const data = new FormData();
// //     data.append(
// //       "user",
// //       new Blob([JSON.stringify(formData)], { type: "application/json" })
// //     );
// //     data.append("companyLogo", companyLogo);

// //     try {
// //       const response = await axios.post(
// //         "http://localhost:8080/api/companysignup/register",
// //         data,
// //         {
// //           headers: { "Content-Type": "multipart/form-data" },
// //         }
// //       );

// //       if (response.status === 200) {
// //         setSuccess(
// //           "OTP sent to your email. Please enter the OTP to complete registration."
// //         );
// //         setIsOtpSent(true);
// //       }
// //     } catch (err) {
// //       if (err.response && err.response.status === 400) {
// //         setError(err.response.data);
// //       } else {
// //         console.error("Error occurred while sending OTP:", err);
// //         setError("An error occurred. Please try again later.");
// //       }
// //     }
// //   };

// //   const handleOtpSubmit = async (e) => {
// //     e.preventDefault();

// //     if (!otp) {
// //       setError("Please enter the OTP.");
// //       return;
// //     }

// //     try {
// //       const response = await axios.post(
// //         `http://localhost:8080/api/companysignup/verify?email=${formData.email}&otp=${otp}`
// //       );

// //       if (response.status === 200) {
// //         setSuccess("Registration successful!");
// //         setError("");
// //         setFormData({
// //           name: "",
// //           email: "",
// //           companyName: "",
// //           companyDesignation: "",
// //           companyPhone: "",
// //         });
// //         setOtp("");
// //         setCompanyLogo(null);
// //         setIsOtpSent(false);
// //       }
// //     } catch (err) {
// //       console.error("Invalid OTP:", err);
// //       setError("Invalid OTP. Please try again.");
// //     }
// //   };

// //   return (
// //     <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
// //       <Toast ref={toast} />
// //       <div className="w-12 md:w-4 lg:w-4 m-auto">
// //         <div className="flex justify-content-center w-full">
// //           <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
// //             <h3 className="text-center">Sign Up</h3>
// //             {!isOtpSent ? (
// //               <form onSubmit={handleSubmit} encType="multipart/form-data">
// //                 <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="name"
// //                       name="name"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter your name"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="email"
// //                       name="email"
// //                       type="email"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter email address"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="companyName"
// //                       name="companyName"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter company name"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="companyDesignation"
// //                       name="companyDesignation"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter company designation"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="companyPhone"
// //                       name="companyPhone"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter company phone"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <input
// //                       type="file"
// //                       id="companyLogo"
// //                       name="companyLogo"
// //                       accept="image/*"
// //                       onChange={handleLogoChange}
// //                       required
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                     />
// //                   </div>

// //                   <Button
// //                     label="Register"
// //                     type="submit"
// //                     className="border-round-lg"
// //                   />
// //                   {error && (
// //                     <div className="alert alert-danger mt-3">{error}</div>
// //                   )}
// //                   {success && (
// //                     <div className="alert alert-success mt-3">{success}</div>
// //                   )}
// //                 </div>
// //               </form>
// //             ) : (
// //               <form onSubmit={handleOtpSubmit}>
// //                 <div className="w-full my-2">
// //                   <InputText
// //                     id="otp"
// //                     name="otp"
// //                     onChange={(e) => setOtp(e.target.value)}
// //                     placeholder="Enter the OTP sent to your email"
// //                     className="p-inputtext-lg w-full border-round-lg"
// //                     required
// //                   />
// //                 </div>

// //                 <Button
// //                   label="Verify OTP"
// //                   type="submit"
// //                   className="border-round-lg"
// //                 />
// //                 {error && (
// //                   <div className="alert alert-danger mt-3">{error}</div>
// //                 )}
// //                 {success && (
// //                   <div className="alert alert-success mt-3">{success}</div>
// //                 )}
// //               </form>
// //             )}
// //           </Card>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default CompanyRegistration;

// // import React, { useState, useRef } from "react";
// // import "./CompanyRegistration.css"; // Optional custom styles
// // import "primereact/resources/themes/saga-blue/theme.css"; // Theme for PrimeReact
// // import "primereact/resources/primereact.min.css"; // PrimeReact styles
// // import { Button } from "primereact/button";
// // import { InputText } from "primereact/inputtext";
// // import { Toast } from "primereact/toast";
// // import { Card } from "primereact/card";
// // import axios from "axios"; // Axios for HTTP requests
// // import images from "..//assets/images"; // Adjust this path based on your project structure

// // function CompanyRegistration() {
// //   const toast = useRef(null); // Toast reference
// //   const [formData, setFormData] = useState({
// //     name: "",
// //     email: "",
// //     companyName: "",
// //     companyDesignation: "",
// //     companyPhone: "",
// //   });

// //   const [companyLogo, setCompanyLogo] = useState(null);
// //   const [otp, setOtp] = useState("");
// //   const [isOtpSent, setIsOtpSent] = useState(false);
// //   const [error, setError] = useState("");
// //   const [success, setSuccess] = useState("");

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value,
// //     });
// //   };

// //   const handleLogoChange = (e) => {
// //     setCompanyLogo(e.target.files[0]);
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     if (
// //       !formData.name ||
// //       !formData.email ||
// //       !formData.companyName ||
// //       !formData.companyDesignation ||
// //       !formData.companyPhone ||
// //       !companyLogo
// //     ) {
// //       setError("All fields, including company logo, are required.");
// //       return;
// //     }

// //     setError("");
// //     setSuccess("");

// //     const data = new FormData();
// //     data.append(
// //       "user",
// //       new Blob([JSON.stringify(formData)], { type: "application/json" })
// //     );
// //     data.append("companyLogo", companyLogo);

// //     try {
// //       const response = await axios.post(
// //         "http://localhost:8080/api/companysignup/register",
// //         data,
// //         {
// //           headers: { "Content-Type": "multipart/form-data" },
// //         }
// //       );

// //       if (response.status === 200) {
// //         setSuccess(
// //           "OTP sent to your email. Please enter the OTP to complete registration."
// //         );
// //         setIsOtpSent(true);
// //       }
// //     } catch (err) {
// //       if (err.response && err.response.status === 400) {
// //         setError(err.response.data);
// //       } else {
// //         console.error("Error occurred while sending OTP:", err);
// //         setError("An error occurred. Please try again later.");
// //       }
// //     }
// //   };

// //   const handleOtpSubmit = async (e) => {
// //     e.preventDefault();

// //     if (!otp) {
// //       setError("Please enter the OTP.");
// //       return;
// //     }

// //     try {
// //       const response = await axios.post(
// //         `http://localhost:8080/api/companysignup/verify?email=${formData.email}&otp=${otp}`
// //       );

// //       if (response.status === 200) {
// //         setSuccess("Registration successful!");
// //         setError("");
// //         setFormData({
// //           name: "",
// //           email: "",
// //           companyName: "",
// //           companyDesignation: "",
// //           companyPhone: "",
// //         });
// //         setOtp("");
// //         setCompanyLogo(null);
// //         setIsOtpSent(false);
// //       }
// //     } catch (err) {
// //       console.error("Invalid OTP:", err);
// //       setError("Invalid OTP. Please try again.");
// //     }
// //   };

// //   return (
// //     <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
// //       <Toast ref={toast} />
// //       <div className="w-12 md:w-4 lg:w-4 m-auto">
// //         <div className="flex justify-content-center w-full">
// //           <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
// //             <div className="flex flex-column align-items-center justify-content-center">
// //               <img
// //                 src={images.logo} // Replace with your actual logo path
// //                 alt="Logo"
// //                 className="logo m-auto"
// //                 style={{ width: "150px", marginBottom: "20px" }} // Adjust size as needed
// //               />
// //               <h3 className="text-center">Sign Up</h3>
// //             </div>

// //             {!isOtpSent ? (
// //               <form onSubmit={handleSubmit} encType="multipart/form-data">
// //                 <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="name"
// //                       name="name"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter your name"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="email"
// //                       name="email"
// //                       type="email"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter email address"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="companyName"
// //                       name="companyName"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter company name"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="companyDesignation"
// //                       name="companyDesignation"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter company designation"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <InputText
// //                       id="companyPhone"
// //                       name="companyPhone"
// //                       onChange={handleInputChange}
// //                       placeholder="Enter company phone"
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                       required
// //                     />
// //                   </div>

// //                   <div className="w-full my-2">
// //                     <input
// //                       type="file"
// //                       id="companyLogo"
// //                       name="companyLogo"
// //                       accept="image/*"
// //                       onChange={handleLogoChange}
// //                       required
// //                       className="p-inputtext-lg w-full border-round-lg"
// //                     />
// //                   </div>

// //                   <Button
// //                     label="Register"
// //                     type="submit"
// //                     className="border-round-lg"
// //                   />
// //                   {error && (
// //                     <div className="alert alert-danger mt-3">{error}</div>
// //                   )}
// //                   {success && (
// //                     <div className="alert alert-success mt-3">{success}</div>
// //                   )}
// //                 </div>
// //               </form>
// //             ) : (
// //               <form onSubmit={handleOtpSubmit}>
// //                 <div className="w-full my-2">
// //                   <InputText
// //                     id="otp"
// //                     name="otp"
// //                     onChange={(e) => setOtp(e.target.value)}
// //                     placeholder="Enter the OTP sent to your email"
// //                     className="p-inputtext-lg w-full border-round-lg"
// //                     required
// //                   />
// //                 </div>

// //                 <Button
// //                   label="Verify OTP"
// //                   type="submit"
// //                   className="border-round-lg"
// //                 />
// //                 {error && (
// //                   <div className="alert alert-danger mt-3">{error}</div>
// //                 )}
// //                 {success && (
// //                   <div className="alert alert-success mt-3">{success}</div>
// //                 )}
// //               </form>
// //             )}
// //           </Card>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default CompanyRegistration;sdbjhsd

// ///////////////latest////////////////////

// import React, { useState, useRef } from "react";
// import "./CompanyRegistration.css"; // Optional custom styles
// import "primereact/resources/themes/saga-blue/theme.css"; // Theme for PrimeReact
// import "primereact/resources/primereact.min.css"; // PrimeReact styles
// import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
// import { Toast } from "primereact/toast";
// import { Card } from "primereact/card";
// import axios from "axios"; // Axios for HTTP requests
// import images from "..//assets/images"; // Adjust this path based on your project structure
// import { Navigate, useNavigate } from "react-router-dom";

// function CompanyRegistration() {
//   const toast = useRef(null); // Toast reference
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     companyName: "",
//     companyDesignation: "",
//     companyPhone: "",
//   });

//   const [companyLogo, setCompanyLogo] = useState(null);
//   const [otp, setOtp] = useState("");
//   const [isOtpSent, setIsOtpSent] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleLogoChange = (e) => {
//     setCompanyLogo(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (
//       !formData.name ||
//       !formData.email ||
//       !formData.companyName ||
//       !formData.companyDesignation ||
//       !formData.companyPhone ||
//       !companyLogo
//     ) {
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: "All fields, including company logo, are required.",
//         life: 3000,
//       });
//       return;
//     }

//     const data = new FormData();
//     data.append(
//       "user",
//       new Blob([JSON.stringify(formData)], { type: "application/json" })
//     );
//     data.append("companyLogo", companyLogo);

//     try {
//       const response = await axios.post(
//         "http://localhost:8080/api/companysignup/register",
//         data,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );

//       if (response.status === 200) {
//         toast.current.show({
//           severity: "success",
//           summary: "Success",
//           detail:
//             "OTP sent to your email. Please enter the OTP to complete registration.",
//           life: 3000,
//         });
//         setIsOtpSent(true);
//       }
//     } catch (err) {
//       if (err.response && err.response.status === 400) {
//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: err.response.data,
//           life: 3000,
//         });
//       } else {
//         console.error("Error occurred while sending OTP:", err);
//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: "An error occurred. Please try again later.",
//           life: 3000,
//         });
//       }
//     }
//   };

//   const handleOtpSubmit = async (e) => {
//     e.preventDefault();

//     if (!otp) {
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: "Please enter the OTP.",
//         life: 3000,
//       });
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `http://localhost:8080/api/companysignup/verify?email=${formData.email}&otp=${otp}`
//       );

//       if (response.status === 200) {
//         toast.current.show({
//           severity: "success",
//           summary: "Success",
//           detail: "Registration successful!",
//           life: 3000,
//         });
//         setFormData({
//           name: "",
//           email: "",
//           companyName: "",
//           companyDesignation: "",
//           companyPhone: "",
//         });
//         setOtp("");
//         setCompanyLogo(null);
//         setIsOtpSent(false);
//       }
//     } catch (err) {
//       console.error("Invalid OTP:", err);
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: "Invalid OTP. Please try again.",
//         life: 3000,
//       });
//     }
//   };

//   const handleHome = () => {
//     navigate("/");
//   };

//   return (
//     <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
//       <Toast ref={toast} />
//       <div className="w-12 md:w-4 lg:w-4 m-auto">
//         <div className="flex justify-content-center w-full">
//           <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
//             <div className="flex flex-column align-items-center justify-content-center">
//               <img
//                 src={images.logo}
//                 alt="Logo"
//                 className="logo m-auto"
//                 style={{
//                   width: "150px",
//                   marginBottom: "20px",
//                   borderRadius: "15px",
//                 }}
//               />
//               <h3 className="text-center">Company Registration</h3>
//             </div>

//             {!isOtpSent ? (
//               <form onSubmit={handleSubmit} encType="multipart/form-data">
//                 <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
//                   <div className="w-full my-2">
//                     <InputText
//                       id="name"
//                       name="name"
//                       onChange={handleInputChange}
//                       placeholder="Enter your name"
//                       className="p-inputtext-lg w-full border-round-lg"
//                       required
//                     />
//                   </div>

//                   <div className="w-full my-2">
//                     <InputText
//                       id="email"
//                       name="email"
//                       type="email"
//                       onChange={handleInputChange}
//                       placeholder="Enter email address"
//                       className="p-inputtext-lg w-full border-round-lg"
//                       required
//                     />
//                   </div>

//                   <div className="w-full my-2">
//                     <InputText
//                       id="companyName"
//                       name="companyName"
//                       onChange={handleInputChange}
//                       placeholder="Enter company name"
//                       className="p-inputtext-lg w-full border-round-lg"
//                       required
//                     />
//                   </div>

//                   <div className="w-full my-2">
//                     <InputText
//                       id="companyDesignation"
//                       name="companyDesignation"
//                       onChange={handleInputChange}
//                       placeholder="Enter company designation"
//                       className="p-inputtext-lg w-full border-round-lg"
//                       required
//                     />
//                   </div>

//                   <div className="w-full my-2">
//                     <InputText
//                       id="companyPhone"
//                       name="companyPhone"
//                       onChange={handleInputChange}
//                       placeholder="Enter company phone"
//                       className="p-inputtext-lg w-full border-round-lg"
//                       required
//                     />
//                   </div>

//                   <div className="w-full my-2">
//                     <input
//                       type="file"
//                       id="companyLogo"
//                       name="companyLogo"
//                       accept="image/*"
//                       onChange={handleLogoChange}
//                       required
//                       className="p-inputtext-lg w-full "
//                     />
//                   </div>
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                       width: "100%",
//                     }}
//                   >
//                     <Button
//                       label="Register"
//                       type="submit"
//                       className="border-round-lg"
//                     />
//                     <p
//                       onClick={handleHome}
//                       style={{
//                         cursor: "pointer",
//                         textDecoration: "underline",
//                         color: "blue",
//                         marginLeft: "10px", // Optional spacing
//                       }}
//                     >
//                       Back to Login
//                     </p>
//                   </div>
//                 </div>
//               </form>
//             ) : (
//               <form onSubmit={handleOtpSubmit}>
//                 <div className="w-full my-2">
//                   <InputText
//                     id="otp"
//                     name="otp"
//                     onChange={(e) => setOtp(e.target.value)}
//                     placeholder="Enter the OTP sent to your email"
//                     className="p-inputtext-lg w-full border-round-lg"
//                     required
//                   />
//                 </div>

//                 <Button
//                   label="Verify OTP"
//                   type="submit"
//                   className="border-round-lg"
//                 />
//               </form>
//             )}
//           </Card>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CompanyRegistration;
import React, { useState, useRef } from "react";
import "./CompanyRegistration.css"; // Optional custom styles
import "primereact/resources/themes/saga-blue/theme.css"; // Theme for PrimeReact
import "primereact/resources/primereact.min.css"; // PrimeReact styles
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import axios from "axios"; // Axios for HTTP requests
import images from "..//assets/images"; // Adjust this path based on your project structure
import { Dialog } from "primereact/dialog"; // Import Dialog for pop-up
import { createAxiosInstance as myAxios } from "../services/Helper";

function CompanyRegistration() {
  const toast = useRef(null); // Toast reference
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    companyDesignation: "",
    companyPhone: "",
  });

  const [companyLogo, setCompanyLogo] = useState(null);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false); // State for dialog visibility

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogoChange = (e) => {
    setCompanyLogo(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.companyName ||
      !formData.companyDesignation ||
      !formData.companyPhone ||
      !companyLogo
    ) {
      setError("All fields, including company logo, are required.");
      return;
    }

    setError("");
    setSuccess("");

    const data = new FormData();
    data.append(
      "user",
      new Blob([JSON.stringify(formData)], { type: "application/json" })
    );
    data.append("companyLogo", companyLogo);

    const axiosInstance = await myAxios();

    try {
      const response = await axiosInstance.post(
        "/companysignup/register",
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        setSuccess(
          "OTP sent to your email. Please enter the OTP to complete registration."
        );
        setIsOtpSent(true);
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Display the error message from the backend
        setError(err.response.data);
      } else {
        console.error("Error occurred while sending OTP:", err);
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (!otp) {
      setError("Please enter the OTP.");
      return;
    }

    const axiosInstance = await myAxios();

    try {
      const response = await axiosInstance.post(
        `/companysignup/verify?email=${formData.email}&otp=${otp}`
      );

      if (response.status === 200) {
        setSuccess("Waiting for admin approval."); // Update message
        setError("");
        setFormData({
          name: "",
          email: "",
          companyName: "",
          companyDesignation: "",
          companyPhone: "",
        });
        setOtp("");
        setCompanyLogo(null);
        setIsOtpSent(false);
        setDisplayDialog(true); // Open dialog for admin approval message
      }
    } catch (err) {
      console.error("Invalid OTP:", err);
      setError("Invalid OTP. Please try again.");
    }
  };

  const dialogFooter = (
    <div>
      <Button label="Close" onClick={() => setDisplayDialog(false)} />
    </div>
  );

  return (
    <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
      <Toast ref={toast} />
      <div className="w-12 md:w-4 lg:w-4 m-auto">
        <div className="flex justify-content-center w-full">
          <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
            <div className="flex flex-column align-items-center justify-content-center">
              <img
                src={images.logo} // Replace with your actual logo path
                alt="Logo"
                className="logo m-auto"
                style={{ width: "150px", marginBottom: "20px" }} // Adjust size as needed
              />
              <h3 className="text-center">Sign Up</h3>
            </div>

            {!isOtpSent ? (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
                  <div className="w-full my-2">
                    <InputText
                      id="name"
                      name="name"
                      onChange={handleInputChange}
                      placeholder="Enter your name"
                      className="p-inputtext-lg w-full border-round-lg"
                      required
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="email"
                      name="email"
                      type="email"
                      onChange={handleInputChange}
                      placeholder="Enter email address"
                      className="p-inputtext-lg w-full border-round-lg"
                      required
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="companyName"
                      name="companyName"
                      onChange={handleInputChange}
                      placeholder="Enter company name"
                      className="p-inputtext-lg w-full border-round-lg"
                      required
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="companyDesignation"
                      name="companyDesignation"
                      onChange={handleInputChange}
                      placeholder="Enter company designation"
                      className="p-inputtext-lg w-full border-round-lg"
                      required
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="companyPhone"
                      name="companyPhone"
                      onChange={handleInputChange}
                      placeholder="Enter company phone"
                      className="p-inputtext-lg w-full border-round-lg"
                      required
                    />
                  </div>

                  <div className="w-full my-2">
                    <input
                      type="file"
                      id="companyLogo"
                      name="companyLogo"
                      accept="image/*"
                      onChange={handleLogoChange}
                      required
                      className="p-inputtext-lg w-full border-round-lg"
                    />
                  </div>

                  <Button
                    label="Register"
                    type="submit"
                    className="border-round-lg"
                  />
                  {error && (
                    <div className="alert alert-danger mt-3">{error}</div>
                  )}
                  {success && (
                    <div className="alert alert-success mt-3">{success}</div>
                  )}
                </div>
              </form>
            ) : (
              <form onSubmit={handleOtpSubmit}>
                <div className="w-full my-2">
                  <InputText
                    id="otp"
                    name="otp"
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter the OTP sent to your email"
                    className="p-inputtext-lg w-full border-round-lg"
                    required
                  />
                </div>

                <Button
                  label="Verify OTP"
                  type="submit"
                  className="border-round-lg"
                />
                {error && (
                  <div className="alert alert-danger mt-3">{error}</div>
                )}
                {success && (
                  <div className="alert alert-success mt-3">{success}</div>
                )}
              </form>
            )}
          </Card>
        </div>
      </div>

      {/* Dialog for admin approval message */}
      <Dialog
        header="Approval Status"
        visible={displayDialog}
        style={{ width: "50vw" }}
        footer={dialogFooter}
        onHide={() => setDisplayDialog(false)}
      >
        <p>Your registration is successful! Please wait for admin approval.</p>
      </Dialog>
    </div>
  );
}

export default CompanyRegistration;
