import React, { useState, useRef, useEffect } from "react";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate } from "react-router-dom";

import "./VaultSecret.css";

export default function CreateSecret() {
  const [sftpVisible, setSftpVisible] = useState(false);
  const [dbVisible, setDbVisible] = useState(false);
  const [sftpError, setSftpError] = useState({});
  const [dbError, setDbError] = useState({});
  const navigate = useNavigate();

  //Only for Admin
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userRole")?.includes("ADMIN")) {
      setIsAdmin(true);
    }
  }, []);

  const [sftpValue, setSftpValue] = useState({
    secretName: "",
    company: "",
    host: "",
    port: "",
    username: "",
    password: "",
    type: "sftp",
  });

  const [dbValue, setDbValue] = useState({
    secretName: "",
    company: "",
    dbUrl: "",
    // tableName: "",
    username: "",
    password: "",
    type: "db",
  });

  const toast = useRef(null);

  const handleSftpInputChange = (e) => {
    const { name, value } = e.target;
    setSftpValue({
      ...sftpValue,
      [name]: value,
    });
    setSftpError({}); // Clear error message on input change
  };

  const handleDbInputChange = (e) => {
    const { name, value } = e.target;
    setDbValue({
      ...dbValue,
      [name]: value,
    });
    setDbError({}); // Clear error message on input change
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 3000 });
  };

  const handleSFTPCreate = async (e) => {
    e.preventDefault();

    // Initialize error state
    const errors = {};

    // Check for empty fields
    if (!sftpValue.secretName) errors.secretName = true;
    if (!sftpValue.company) errors.company = true;
    if (!sftpValue.host) errors.host = true;
    if (!sftpValue.port) errors.port = true;
    if (!sftpValue.username) errors.username = true;
    if (!sftpValue.password) errors.password = true;

    // Set errors and return if there are any
    if (Object.keys(errors).length > 0) {
      setSftpError(errors);
      return;
    }

    const requestData = {
      name: sftpValue.secretName,
      type: sftpValue.type,
      company: sftpValue.company,
      data: [
        { name: "host", value: sftpValue.host },
        { name: "port", value: sftpValue.port },
        { name: "username", value: sftpValue.username },
        { name: "password", value: sftpValue.password },
      ],
    };

    const axiosInstance = await myAxios();

    try {
      const response = await axiosInstance.post("/secrets/create", requestData).then((res)=> res.data).catch((e)=> e.response);

      // if (!response.ok) {
      //   throw new Error("Network Response not Ok");
      // }

        if(response.status === 200 ){

          showToast("success", "Success", "Secrets created successfully!");
        }
        else if (response.status === 401){
         navigate("/login"); 
        }
      // const responseData = await response.json();
      // console.log("Response from API:", responseData);
      setSftpVisible(false); // Close the dialog on success
      // Reset form values after successful submission
      setSftpValue({
        secretName: "",
        company: "",
        host: "",
        port: "",
        username: "",
        password: "",
        type: "sftp",
      });
    } catch (error) {
      console.log("error : ", error);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
  };

  const handleDBCreate = async (e) => {
    e.preventDefault();

    // Initialize error state
    const errors = {};
    // Check for empty fields
    if (!dbValue.secretName) errors.secretName = true;
    if (!dbValue.company) errors.company = true;
    if (!dbValue.dbUrl) errors.dbUrl = true;
    if (!dbValue.username) errors.username = true;
    if (!dbValue.password) errors.password = true;

    // Set errors and return if there are any
    if (Object.keys(errors).length > 0) {
      console.log(errors);
      setDbError(errors);
      return;
    }

    const requestData = {
      name: dbValue.secretName,
      type: dbValue.type,
      company: dbValue.company,
      data: [
        { name: "dburl", value: dbValue.dbUrl },
        { name: "username", value: dbValue.username },
        { name: "password", value: dbValue.password },
      ],
    };

    const axiosInstance = await myAxios();

    try {
      const response = await axiosInstance
        .post("/secrets/create", requestData)
        .then((res) => res.data)
        .catch((e) => e.response);

        if (response.status === 200) {
          showToast("success", "Success", "Secrets created successfully!");
        } else if (response.status === 401) {
          navigate("/login");
        }
      setDbVisible(false); // Close the dialog on success
      // Reset form values after successful submission
      setDbValue({
        secretName: "",
        company: "",
        dbUrl: "",
        username: "",
        password: "",
        type: "db",
      });
    } catch (error) {
      console.log(error.message);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
  };

  const sftpFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setSftpVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleSFTPCreate}
        autoFocus
      />
    </div>
  );

  const dbFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDbVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleDBCreate}
        autoFocus
      />
    </div>
  );

  const renderMandatoryFieldMessage = (value, error) => {
    if (error) {
      return <span style={{ color: "red" }}>This field is required</span>;
    }
    return null;
  };

  return (
    <>
      {!isAdmin && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1>You don't have access to this page please reach to Admin.</h1>
        </div>
      )}
      {isAdmin && (
        <div>
          <Toast ref={toast} />
          <div className="card" style={{ width: "100%" }}>
            <h1 className="Header">Create Secret </h1>
            <Divider />
          </div>
          <div className="card1">
            <Card title="Select One of Them">
              <div className="card flex flex-wrap justify-content-start">
                <Button
                  label="SFTP Secrets"
                  severity="success"
                  onClick={() => setSftpVisible(true)}
                />
                <Button
                  className="ml-5"
                  label="Database Secrets"
                  severity="success"
                  onClick={() => setDbVisible(true)}
                />
              </div>
            </Card>
          </div>

          {/* SFTP Secrets Dialog */}
          <Dialog
            header="SFTP Secret Details"
            visible={sftpVisible}
            style={{ width: "30vw" }} // Adjust the width as needed
            onHide={() => setSftpVisible(false)}
            footer={sftpFooterContent}
          >
            <div className="flex flex-column align-items-start">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="sftpName">Name of Secrets</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.secretName,
                    sftpError.secretName
                  )}
                </div>
                <InputText
                  id="sftpName"
                  name="secretName"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>

              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="host">Company Name</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.company,
                    sftpError.company
                  )}
                </div>
                <InputText
                  id="company"
                  name="company"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="host">SFTP Host</label>
                <div>
                  {renderMandatoryFieldMessage(sftpValue.host, sftpError.host)}
                </div>
                <InputText
                  id="host"
                  name="host"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="sftpPort">Port</label>
                <div>
                  {renderMandatoryFieldMessage(sftpValue.port, sftpError.port)}
                </div>
                <InputText
                  id="sftpPort"
                  name="port"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="sftpUserName">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.username,
                    sftpError.username
                  )}
                </div>
                <InputText
                  id="sftpUserName"
                  name="username"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="sftpPassword">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.password,
                    sftpError.password
                  )}
                </div>
                <InputText
                  id="sftpPassword"
                  name="password"
                  type="password"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
            </div>
          </Dialog>

          {/* Database Secrets Dialog */}
          <Dialog
            header="Database Secret Details"
            visible={dbVisible}
            style={{ width: "30vw" }} // Adjust the width as needed
            onHide={() => setDbVisible(false)}
            footer={dbFooterContent}
          >
            <div className="flex flex-column align-items-start">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="dbName">Secret Name</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.secretName,
                    dbError.secretName
                  )}
                </div>
                <InputText
                  id="dbName"
                  name="secretName"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>

              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="host">Company Name</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.company,
                    dbError.company
                  )}
                </div>
                <InputText
                  id="company"
                  name="company"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="dbUrl">Database URL</label>
                <div>
                  {renderMandatoryFieldMessage(dbValue.dbUrl, dbError.dbUrl)}
                </div>
                <InputText
                  id="dbUrl"
                  name="dbUrl"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>

              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="dbUserName">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.username,
                    dbError.username
                  )}
                </div>
                <InputText
                  id="dbUserName"
                  name="username"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="dbPassword">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.password,
                    dbError.password
                  )}
                </div>
                <InputText
                  id="dbPassword"
                  name="password"
                  type="password"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
