import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Breadcrumbs from "../Breadcrumb";
import "./FileDetails.css"; // Assuming you have some shared styles
import { createAxiosInstance as myAxios } from "../../services/Helper";

export default function DatabaseDetails() {
  const location = useLocation();
  const { ruleId, targetTableName } = location.state || {};
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(200); // Number of records per page
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      if (!ruleId || !targetTableName) {
        console.error("Missing ruleId or targetTableName");
        return;
      }

      setLoading(true); // Start loading

      try {
        const axiosInstance = await myAxios();
        const response = await axiosInstance.get(
          `filess?id=${ruleId}&archivePath=${encodeURIComponent(
            targetTableName
          )}`
        );
        const data = response.data;

        console.log("In DB reading");
        console.log(data);

        if (data.data && data.data.length > 0) {
          const columnNames = Object.keys(data.data[0]);
          setColumns(columnNames);
          // Initialize filters for each column
          const initialFilters = columnNames.reduce((acc, col) => {
            acc[col] = { value: null, matchMode: FilterMatchMode.CONTAINS };
            return acc;
          }, {});
          setFilters((prevFilters) => ({
            ...prevFilters,
            ...initialFilters,
          }));
          setData(data.data); // Ensure to set the correct data
        } else {
          setData([]); // Set empty data if no data returned
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]); // Set empty data on error
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [ruleId, targetTableName]); // Re-run effect when ruleId or targetTableName changes

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue(value);
  };

  console.log(columns);
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={{ width: "15rem" }} // Match the style from the old code
        />
      </div>
    );
  };

  return (
    <>
      <div style={{ marginLeft: "5px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <div className="loading-container">
        {loading ? (
          <div className="spinner-container">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
          <div className="card">
            <DataTable
              value={data}
              paginator
              rows={rowsPerPage}
              first={page * rowsPerPage}
              onPage={(event) => setPage(event.page)}
              filters={filters}
              globalFilterFields={columns}
              header={renderHeader()}
              tableStyle={{ minWidth: "50rem" }}
            >
              {columns.map((col) => (
                <Column
                  key={col}
                  field={col}
                  header={col}
                  filter
                  filterPlaceholder={`Search by ${col}`}
                  style={{ width: "20%" }}
                />
              ))}
            </DataTable>
          </div>
        )}
      </div>
    </>
  );
}
