import "./App.css";
import Login from "./components/LoginSignupComponents/Login";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ForgotPassword from "./components/LoginSignupComponents/ForgotPassword";
import HomeNavbar from "./components/LandingPageComponent/HomeNavbar";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Profile from "./Pages/Profile";
import Users from "./Pages/Users";
import UserRegistration from "./Pages/UserRegistration";
import { ToastContainer } from "react-toastify";
import TimeScheduling from "./components/scheduleComponent/TimeScheduling";
import Createrule from "./components/RuleComponent/createrule";
import EditMountRules from "./Rules/EditRules/EditMountRules";
import EditDbToMountRules from "./Rules/EditRules/EditDbToMountRules";
import EditMountDBRules from "./Rules/EditRules/EditMountDBRules";
import History from "./components/History/History";
import FileDetails from "./components/History/FileDetails";
import ProtectedRoute from "./components/contextAPIs/ProtectedComponent";
import Changepass from "./components/LoginSignupComponents/Changepass";
import VerifyOtp from "./components/LoginSignupComponents/VerifyOtp";
import EditDatabaseToDb from "./Rules/EditRules/EditDatabaseToDb";
import DatabaseDetails from "./components/History/DatabaseDetails";
import { Home as OldHome } from "./DocumentReview/src/components/Home/Home";
import { SigninComponent } from "./DocumentReview/src/components/form/signin";
import { SignupComponent } from "./DocumentReview/src/components/form/signup";
import TermsAndConditions from "./components/LandingPageComponent/TermsAndConditions";
import CreateSecret from "./vaultSecrets/CreateSecret";
import DatabaseTransferFile from "./vaultSecrets/DatabaseTransferFile";
import SftpTransferFile from "./vaultSecrets/SftpTransferFile";
import SftptoDbTransfer from "./vaultSecrets/SftptoDbTransfer";
import DbtoSftpTransfer from "./vaultSecrets/DbtoSftpTransfer";
import CompanyRegistration from "./CompanyRegistration/CompanyRegistration";

function App() {
  const ConditionalNavbar = () => {
    const location = useLocation();

    if (
      location.pathname === "/" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/login" ||
      location.pathname === "/changepassword" ||
      location.pathname === "/verify" ||
      location.pathname === "/companyRegistration"
    ) {
      return null;
    }

    return <HomeNavbar />;
  };

  return (
    <div className="App">
      <ToastContainer />
      <ConditionalNavbar />
      <Routes>
        <Route path="/companyRegistration" element={<CompanyRegistration />} />
        {/* Old Routes */}
        <Route
          path="/document-review/signin"
          element={
            <ProtectedRoute>
              {" "}
              <SigninComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/document-review/signup"
          element={
            <ProtectedRoute>
              {" "}
              <SignupComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/document-review/home"
          element={
            <ProtectedRoute>
              {" "}
              <OldHome />
            </ProtectedRoute>
          }
        />

        {/* New Routes */}
        <Route path="/" element={<TermsAndConditions />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/changepassword" element={<Changepass />} />
        <Route path="/verify" element={<VerifyOtp />} />
        <Route
          path="/about"
          element={
            <ProtectedRoute>
              <About />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />
        <Route
          path="/services"
          element={
            <ProtectedRoute>
              <Services />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register"
          element={
            <ProtectedRoute>
              <UserRegistration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dtd/rules/edit"
          element={
            <ProtectedRoute>
              <EditDatabaseToDb />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mtm/rules/edit"
          element={
            <ProtectedRoute>
              <EditMountRules />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dtm/rules/edit"
          element={
            <ProtectedRoute>
              <EditDbToMountRules />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mtd/rules/edit"
          element={
            <ProtectedRoute>
              <EditMountDBRules />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rules/create"
          element={
            <ProtectedRoute>
              <Createrule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home/history"
          element={
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home/history/file/:id"
          element={
            <ProtectedRoute>
              <FileDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home/history/database/:id"
          element={
            <ProtectedRoute>
              <DatabaseDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/createSecret"
          element={
            <ProtectedRoute>
              <CreateSecret />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sftp-transfer"
          element={
            <ProtectedRoute>
              <SftpTransferFile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/database-transfer"
          element={
            <ProtectedRoute>
              <DatabaseTransferFile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sftp-database"
          element={
            <ProtectedRoute>
              <SftptoDbTransfer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/database-sftp"
          element={
            <ProtectedRoute>
              <DbtoSftpTransfer />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
      <TimeScheduling />
    </div>
  );
}

export default App;
