import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import Breadcrumbs from "../../components/Breadcrumb";
import { ContextTimeScheduling } from "../../components/scheduleComponent/ContextTimeScheduling";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";

const parseCronExpression = (cronExpression) => {
  const parts = cronExpression.split(" ");

  const getValueAfterSlash = (part) => {
    if (part.includes("/")) {
      const splitPart = part.split("/");
      return splitPart[1]; // Return the value after '/'
    } else if (part === "*")
      return "0"; // Return the original part if '/' is not present
    else return part;
  };
  return {
    seconds: getValueAfterSlash(parts[0]), // Assuming seconds is included in the cron expression
    minutes: getValueAfterSlash(parts[1]),
    hours: getValueAfterSlash(parts[2]),
    dayOfMonth: getValueAfterSlash(parts[3]),
    month: getValueAfterSlash(parts[4]),
    dayOfWeek: getValueAfterSlash(parts[5]),
  };
};

export default function DbToMountRules() {
  const [ruleData, setRuleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  ///Here state for test connection
  // const [loadingTest, setLoadingTest] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  //added for limit query.
  const [limitErrorMessage, setLimitErrorMessage] = useState("");
  const [isLimitValid, setIsLimitValid] = useState(true);

  const location = useLocation();
  const { ruleId } = location.state || {};
  const toast = useRef(null);
  const { setOpenDialog, setcontextFormData } = useContext(
    ContextTimeScheduling
  );

  const [formData, setFormData] = useState({
    server1: "",
    port: "",
    sftpUser: "",
    sftpPass: "",
    filePath: "",
    fileName: "",
    tableName: "",
    jobname: "",
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    archiveLocation: "",
    seconds: "",
    minutes: "",
    hours: "",
    dayOfMonth: "",
    month: "",
    dayOfWeek: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    ruleId: "",
    queryText: "",
    addQuery: false,
  });

  useEffect(() => {
    if (ruleId === undefined) navigate("/home");
    const fetchData = async () => {
      try {
        const axiosInstance = await myAxios();
        const response = await axiosInstance.get(`/dtm/sch/getByID/${ruleId}`);
        setRuleData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }
  }, [ruleId]);

  useEffect(() => {
    if (ruleData) {
      const cronValues = parseCronExpression(ruleData.cronExpression);
      const startTime = ruleData.startDate
        ? ruleData.startDate.split("T")[1]
        : "";
      setFormData({
        server1: ruleData.server || "",
        port: ruleData.port.toString() || "",
        sftpUser: ruleData.sftpUser || "",
        sftpPass: ruleData.sftpPass || "",
        filePath: ruleData.filePath || "",
        fileName: ruleData.fileName || "",
        jobname: ruleData.jobname || "",
        tableName: ruleData.tableName || "",
        dbUrl: ruleData.dbUrl || "",
        dbUser: ruleData.dbUser || "",
        dbPass: ruleData.dbPass || "",
        archiveLocation: ruleData.archiveLocation || "",
        ...cronValues,
        timeZone: ruleData.timeZone || "",
        startDate: ruleData.startDate || "",
        startTime: startTime || "",
        endDate: ruleData.endDate || "",
        ruleId: ruleData.id || "",
        queryText: ruleData.queryText || "",
        addQuery: !!ruleData.queryText,
      });
    }
  }, [ruleData]);

  ///////////////////////////////////////////////////////////////////////////////////////////
  // added for test connection for sftp1

  const [sftpServer1, setSftpServer1] = useState({
    server1: "",
    port1: "",
    user1: "",
    pass1: "",
    filePath: "",
  });

  // added for the database connection

  const [databaseConnectionTest, setDatabaseConnectionTest] = useState({
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    tableName: "",
  });

  //useEffect on outside
  useEffect(() => {
    if (sftpServer1) {
      // // console.log(sftpServer1);
      if (checkSftpValid()) {
        // // console.log("Going to Check the connection Values ");
        // call handleTest
        setLoading(true);
        setVisible(true);
        callTestSFTP();
      }
    }
  }, [sftpServer1]);

  // useEffect on OutSide the function for Testing DBConnection Test
  useEffect(() => {
    if (databaseConnectionTest) {
      // // console.log(databaseConnectionTest);
      if (checkDatabaseConnectionValid()) {
        // // console.log("Going To Check the Dattabase Connection Test ");
        // // call the api here
        // callTestDatabase();

        // // call handleTest
        // setVisible(true);
        setLoading(true);
        setVisible(true);
        callTestDatabase();
      }
    }
  }, [databaseConnectionTest]);

  const checkDatabaseConnectionValid = () => {
    for (const key in databaseConnectionTest) {
      if (databaseConnectionTest[key] === "") {
        return false;
      }
    }

    return true;
  };

  const checkSftpValid = () => {
    for (const key in sftpServer1) {
      if (sftpServer1[key] === "") {
        return false; // Return false if any field is empty
      }
    }
    return true;
  };

  const callTestSFTP = async () => {
    try {
      const axiosInstance = await myAxios();
      let sftp_connect = await axiosInstance
        .post("/test/con/sftp", sftpServer1)
        .then((res) => res.data);

      // // console.log(sftp_connect);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  // handle the database  ApI call here
  const callTestDatabase = async () => {
    try {
      const axiosInstance = await myAxios();
      let sftp_connect = await axiosInstance
        .post("/test/con/db", databaseConnectionTest)
        .then((res) => res.data)
        .catch((e) => e.response.data);
      // // console.log(sftp_connect);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      // update code added here for message and status
      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (
        formData.filePath.trim() === "" ||
        formData.fileName.trim() === "" ||
        formData.server1.trim() === "" ||
        formData.port.trim() === "" ||
        formData.sftpUser.trim() === "" ||
        formData.sftpPass.trim() === ""
      ) {
        // // console.log(checkSftpValid(), " sftp details ");
        // // console.log(sftpServer1);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Target's required fields .",
          life: 3000,
        });
        return;
      } else {
        setSftpServer1({
          server1: formData.server1,
          port1: formData.port,
          user1: formData.sftpUser,
          pass1: formData.sftpPass,
          // filePath: formData.filePath + "/" + formData.fileName,
          filePath: formData.filePath,
        });
      }
    }
  };

  // handle test connection
  const handleTestForDBConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (
        formData.dbPass.trim() === "" ||
        formData.dbUser.trim() === "" ||
        formData.dbUrl.trim() === "" ||
        formData.tableName.trim() === ""
      ) {
        // // // console.log(checkDatabaseConnectionValid() , " sftp details ") ;
        // // console.log(databaseConnectionTest);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Source's required fields .",
          life: 3000,
        });
        return;
      } else {
        setDatabaseConnectionTest({
          dbUrl: formData.dbUrl,
          dbUser: formData.dbUser,
          dbPass: formData.dbPass,
          tableName: formData.tableName,
        });
      }
    }
  };

  // handle Test condition
  /////////////////////////////////////////////////////////////////////////////////////////

  const handleClick = (e) => {
    e.preventDefault();
    // Check for required fields
    const requiredFields = [
      "server1",
      "port",
      "sftpUser",
      "sftpPass",
      "filePath",
      "fileName",
      "tableName",
      "jobname",
      "dbUrl",
      "dbUser",
      "dbPass",
      "archiveLocation",
      "seconds",
      "minutes",
      "hours",
      "dayOfMonth",
      "month",
      "dayOfWeek",
      "timeZone",
      "startDate",
    ];
    const isEmpty = requiredFields.some((field) => !formData[field]);

    // adding for limit query error
    if (!isLimitValid) {
      toast.current.show({
        severity: "error",
        summary: "SQL Query Limit ",
        detail: "You can take Limited Query Amount else Opt for offset",
        life: 2000,
      });
      return;
    }

    if (isEmpty) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill all required fields.",
      });
    } else {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    }
  };

  //////////////////////////////////////////////////////////////////////

  // useEffect to see the values
  useEffect(() => {
    // Regular expression to find the number after the LIMIT keyword
    const limitMatch = formData.queryText.match(/LIMIT\s+(\d+)/i);

    if (limitMatch) {
      const limitValue = limitMatch[1]; // Extracted number
      if (limitValue > 100000) {
        setLimitErrorMessage("Cant' Exceed more than 1 lakh");
        setIsLimitValid(false);
        // console.log(
        //   "can't go more than 1 lakh records try to break down in smaller chunk with the limit and offset value "
        // );
      } else {
        // console.log("Limit value:", limitValue); // Output: 10
        setLimitErrorMessage("");
        setIsLimitValid(true);
      }
    } else {
      // console.log("No LIMIT clause found");
      setLimitErrorMessage("");
      setIsLimitValid(true);
    }
  }, [formData.queryText, limitErrorMessage]);

  /////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    if (name === "addQuery" && !val) {
      setFormData({ ...formData, [name]: val, queryText: "" });
    } else {
      setFormData({ ...formData, [name]: val });
    }
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <div style={{ marginLeft: "25px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <span className="text-2xl font-bold">
                Database To Mount Transfer
              </span>
            </div>
            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  required
                />
              </div>
            </div>
            <div className="col-12 sm:col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Database URL
                    </label>
                    <InputText
                      type="password"
                      id="dns2_input"
                      name="dbUrl"
                      className="w-full"
                      value={formData.dbUrl}
                      onChange={handleChange}
                      placeholder="Enter Database URL"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="tableName_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="tableName_input"
                      name="tableName"
                      className="w-full"
                      value={formData.tableName}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="username2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      User Name
                    </label>
                    <InputText
                      id="username2_input"
                      name="dbUser"
                      className="w-full"
                      value={formData.dbUser}
                      onChange={handleChange}
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="password2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Password
                    </label>
                    <Password
                      id="password2_input"
                      name="dbPass"
                      className="w-full"
                      value={formData.dbPass}
                      feedback={false}
                      onChange={handleChange}
                      placeholder="********"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="addQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="addQuery"
                      name="addQuery"
                      checked={formData.addQuery}
                      onChange={handleChange}
                    />
                  </div>

                  {formData.addQuery && (
                    <div className="group">
                      {limitErrorMessage && (
                        <p style={{ color: "red" }}>{limitErrorMessage}</p>
                      )}
                      <InputTextarea
                        name="queryText"
                        placeholder="Enter SQL query here"
                        className="w-full"
                        autoResize
                        value={formData.queryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>

                {/* <div className="col-12"></div> */}
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Target Host
                    </label>
                    <InputText
                      type="password"
                      id="dns_input"
                      name="server1"
                      className="w-full"
                      value={formData.server1}
                      onChange={handleChange}
                      placeholder="Enter SFTP Host"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="source_port_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Target Port
                    </label>
                    <InputText
                      id="source_port_input"
                      name="port"
                      className="w-full"
                      value={formData.port}
                      onChange={handleChange}
                      placeholder="Enter Port Number"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="username_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      User Name
                    </label>
                    <InputText
                      id="username_input"
                      name="sftpUser"
                      className="w-full"
                      value={formData.sftpUser}
                      onChange={handleChange}
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="password_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Password
                    </label>
                    <Password
                      id="password_input"
                      name="sftpPass"
                      className="w-full"
                      value={formData.sftpPass}
                      feedback={false}
                      onChange={handleChange}
                      placeholder="********"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="filePath"
                      className="w-full"
                      value={formData.filePath}
                      onChange={handleChange}
                      placeholder="Enter Target Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <Dialog
                  header="Connection Test"
                  visible={visible}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible(false)}
                  footer={footerContent}
                >
                  <Card>
                    {" "}
                    {loading ? (
                      <div className="card flex justify-content-center">
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="5"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <>
                        <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                        <p className="m-0">Message: {responseMessage}</p>
                      </>
                    )}
                  </Card>
                </Dialog>
              </div>
            </div>

            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
